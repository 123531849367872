var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title:
          "药品成分管理-规格ID[" + _vm.specId + "-" + _vm.singleSpecId + "]",
        visible: _vm.EditDialogVisible,
        "before-close": _vm.closeDpEditDialog,
        width: "70%",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.EditDialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            { staticClass: "title-bar", attrs: { span: 12, id: "a04" } },
            [_vm._v("药品成分(包含成分)")]
          ),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "text-align": "right", background: "#2B60F8" },
                  attrs: { type: "primary" },
                  on: { click: _vm.editSpecIngredient },
                },
                [_vm._v(" 添加成分 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { padding: "0px 0px 15px 15px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  ref: "rno",
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.dpSpecIngredientList,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      property: "id",
                      label: "成分ID",
                      width: "100",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "substanceName",
                      label: "物质",
                      "min-width": "100",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "substanceQty",
                      label: "物质数量",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.substanceQty) +
                                " " +
                                _vm._s(scope.row.substanceQtyUnitDesc) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "ingredientTypeDesc",
                      label: "成分类型",
                      width: "100",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "substanceReferenceDesc",
                      label: "物质参考说明",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "150", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSpecIngredient(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delSpecIngredient(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        [
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { type: "primary" },
              on: { click: _vm.submitSpecIngredientForm },
            },
            [_vm._v(" 保存成分 ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "成分编辑",
            "append-to-body": "",
            width: "60%",
            visible: _vm.addSpecIngredientDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSpecIngredientDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "addSpecIngredientForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.specIngredientForm,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "成分物质:",
                                prop: "substanceId",
                                rules: _vm.specIngredientFormRule.substanceId,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "280px",
                                    float: "left",
                                  },
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                    placeholder: "请输入关键词搜索",
                                    "remote-method": _vm.querySubstanceName,
                                    loading: _vm.loading,
                                  },
                                  model: {
                                    value: _vm.specIngredientForm.substanceId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "substanceId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.substanceId",
                                  },
                                },
                                _vm._l(_vm.substanceList, function (item) {
                                  return _c("el-option", {
                                    key: "substanceList" + item.substanceId,
                                    attrs: {
                                      label: item.name,
                                      value: item.substanceId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "成分类型:",
                                prop: "ingredientType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    float: "left",
                                  },
                                  model: {
                                    value:
                                      _vm.specIngredientForm.ingredientType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "ingredientType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.ingredientType",
                                  },
                                },
                                _vm._l(
                                  _vm.drugIngredientTypeDict,
                                  function (item) {
                                    return _c("el-option", {
                                      key:
                                        "drugIngredientTypeDict" +
                                        item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质数量:",
                                prop: "substanceQty",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  min: 0,
                                  controls: "",
                                  "controls-position": "right",
                                  precision: 4,
                                },
                                model: {
                                  value: _vm.specIngredientForm.substanceQty,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specIngredientForm,
                                      "substanceQty",
                                      $$v
                                    )
                                  },
                                  expression: "specIngredientForm.substanceQty",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质数量单位:",
                                prop: "substanceQtyUnitId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    float: "left",
                                  },
                                  attrs: {
                                    filterable: "",
                                    "filter-method": _vm.substanceQtyUnitFilter,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.quantityUnitDictOptions =
                                        _vm.quantityUnitDict
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.specIngredientForm.substanceQtyUnitId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "substanceQtyUnitId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.substanceQtyUnitId",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "", value: "" },
                                  }),
                                  _vm._l(
                                    _vm.quantityUnitDictOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key:
                                          "quantityUnitDict" + item.conceptId,
                                        attrs: {
                                          label: item.conceptVal,
                                          value: item.conceptId,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质参考说明:",
                                prop: "substanceReferenceDesc",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "500px", float: "left" },
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入物质参考说明",
                                  maxlength: "200",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value:
                                    _vm.specIngredientForm
                                      .substanceReferenceDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specIngredientForm,
                                      "substanceReferenceDesc",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "specIngredientForm.substanceReferenceDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                background: "#2B60F8",
                                float: "right",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmSpecIngredient },
                            },
                            [_vm._v("确定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: { click: _vm.cancelSpecIngredient },
                            },
                            [_vm._v("取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }