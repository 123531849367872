<template>
  <el-container style="background:#fff;height: auto;overflow: overlay;flex-direction: column;">
    <el-row style="padding: 20px;flex-grow: 1;overflow: hidden overlay;" v-loading="loading">
      <el-row>
        <el-col :span="24" class="inside-menu-wrap">
          <ul class="inside-menu">
            <li v-for="(item,index) in menulist" :key="index" :class="flag==index?'liActive':''" @click="chooseMenu(index)"><a :href="item.href">{{item.name}}</a></li>
          </ul>
        </el-col>
      </el-row>
      <el-col :span="24" class="title-bar" id="a01">制剂基本信息</el-col>
      <el-form ref="editForm" status-icon :model="preparation" label-width="140px">
        <el-row>
          <el-col :span="22">
            <el-form ref="drugComponentForm" status-icon :model="drugComponent" label-width="140px">
              <el-form-item label="制剂ID:"  v-show="preparation.preparationId">
                <el-input v-model="preparation.preparationId" :disabled="true" style="width: 300px;float: left"/>
              </el-form-item>
              <el-form-item label="所属药物" prop="drugComponentQueryStr"
                            :rules="[{ required: true, message: '请输入查询内容', trigger: 'blur'},{ min: 1, max: 30, message: '查询内容长度在 1 到 30 个字符', trigger: 'blur' }]"
              >
                <div v-if="preparation.drugId"><span class="belongDrug">{{ preparation.drugName }}<i
                  class="el-icon-remove delIcon" @click="deldrug(preparation.drugName)"
                ></i></span></div>
                <el-select
                  v-model="drugComponent.drugComponentQueryStr"
                  filterable
                  remote
                  popper-class="select_component"
                  :remote-method="remoteMethod_drug"
                  reserve-keyword
                  placeholder="请输入药物名称"
                  style="width:300px;"
                  clearable
                  @clear="delDrug"
                  @change="(val)=>changeRemote_drug(val)"
                >
                  <div class="clearfix table_Header">
                    <span style="width:100px;flex:1;">药物Id</span>
                    <span style="width:200px;flex:2;">药物名称</span>
                    <span style="width:200px;flex:2;">别名</span>
                    <span style="width:100px;flex:1;">操作</span>
                  </div>
                  <el-option
                    v-for="(item, index) in drugComponentTableData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                    <div class="clearfix spanWrap">
                      <p :title="item.id"
                         style="width:100px;flex:1;"
                      >{{ item.id }}</p>
                      <p :title="item.name"
                         style="width:200px;flex:2;"
                      >{{ item.name }}</p>
                      <p style="width:300px;flex:2;"
                      >
                        <span
                          v-for="aliasItem in item.dkbDrugAliasList"
                          v-show="aliasItem.alias!=item.name"
                        >{{aliasItem.alias}};</span>
                      </p>
                      <p style="width:100px;flex:1;color:#2B60F8;" @click="addDrug(index, item)">添加</p>
                    </div>
                  </el-option>
                </el-select>
                <el-button icon="el-icon-circle-plus-outline" style="margin-left: 20px;"
                           @click="addSetDrug"
                           class="addClass"
                >新增药物
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-form-item
          v-for="(item, index) in preparation.preparationAliasList"
          :label="index==0?'制剂名称:':''"
          :key="'preparation-name-'+index"
          :rules="[
            { required: true, message: '请输入制剂名称', trigger: 'blur'},
            { validator: validatePreparationAlias, trigger: 'blur'}
            ]"

          :prop="'preparationAliasList.' + index + '.preparationAlias'"
        >
          <el-input placeholder="请输入制剂名称" v-model="item.preparationAlias" style="width: 450px;float: left"
                    maxlength="300" clearable show-word-limit
          />
          <el-select v-model="item.languageId" placeholder="请选择语言" style="width: 80px;float: left">
            <el-option
              v-for="item in languageConcep"
              :key="item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <el-form-item style="float: left;margin-bottom: 0px;"
                        :rules="[
          { required: true, message: '请选择名称类型', trigger: 'blur'},
           { validator: validateAliasType, trigger: 'blur'}
          ]"
                        :prop="'preparationAliasList.' + index + '.aliasType'"
          >
            <el-select v-model="item.aliasType" placeholder="请选择类型" style="width: 120px;float: left">
              <el-option
                v-for="item in aliasTypeConcept"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>

          <i class="el-icon-remove" @click.prevent="delSubstanceName(item)"></i>
          <el-radio v-model="preparation.namePrimary" :label="index" border size="small"
                    @change="primaryChange($event,'name',index)"
                    style="margin-right: 10px;padding: 8px 8px 0 5px;"
          >
            首选项
          </el-radio>
          <span></span>
          <el-button icon="el-icon-document-copy" style="margin-left: 4px;" type="primary"
                     @click="copyDrugName(item)" title="复制药物名称"
                     v-if="index == preparation.preparationAliasList.length-1"
          ></el-button>
          <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                     v-if="index == preparation.preparationAliasList.length-1"
                     @click="addSubstanceName('中文')"
          >
            <i class="el-icon-circle-plus"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
            ></i>
            <svg-icon icon-class="china" style="width: 24px;height: 16px;"/>
          </el-button>
          <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                     v-if="index == preparation.preparationAliasList.length-1"
                     @click="addSubstanceName('英语')"
          >
            <i class="el-icon-circle-plus"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
            ></i>
            <svg-icon icon-class="yingguoguoqi" style="width: 24px;height: 16px;"/>
          </el-button>
        </el-form-item>

<!--        <el-form-item
          v-if="classify !== 100403"
          label="剂型"
          prop="type"
          :rules="[{ required: true, message: '请输入剂型', trigger: 'change'}]"
        >
          <el-select v-model="preparation.type" placeholder="请选择剂型" clearable style="width: 300px;float: left"
                     filterable
                     :filter-method="typeFilter"
                     @focus="preparationTypeList=preparationTypeDict"
                     @change="changeType(preparation.type)"
          >
            <el-option
              v-for="item in preparationTypeList"
              :key="item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item v-if="classify !== 100403" label="剂型"
                      prop="type" :rules="[{ required: true, message: '请选择剂型', trigger: 'change'}]"
        >
          <treeselect style="width: 300px;float: left"
                      v-if="selectFormTreeShow"
                      v-model="preparation.type"
                      :options="selectFormTreeData"
                      :normalizer="normalizerTreeNode"
                      :disable-branch-nodes="true"
                      :show-count="true"
                      placeholder="选择剂型"
                      :matchKeys='["label","spell","wubi"]'
                      @select="changeType"
          />
        </el-form-item>
        <el-form-item
          v-if="classify === 100403"
          label="中药类别与规格："
          prop="drugTypeSpecs"
        >
          <el-select v-model="preparation.drugTypeSpecs" placeholder="请选择中药类别与规格" style="width: 300px;float: left"
                     filterable
                     remote
                     :remote-method="remoteMethod_drugTypeSpecs"
                     reserve-keyword
                     clearable
          >
            <el-option
              v-for="item in drugTypeSpecsList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="classify === 100403"
          label="中药炮制方法："
          prop="drugProcMethod"
        >
          <el-select v-model="preparation.drugProcMethod" placeholder="请选择中药炮制方法" style="width: 300px;float: left"
                     filterable
                     remote
                     :remote-method="remoteMethod_drugProc"
                     reserve-keyword
                     clearable
          >
            <el-option
              v-for="item in drugProcMethod"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="浓度" style="display: none;">
          <el-input v-model="preparation.concentration"
                    @keyup.native="preparation.concentration = oninput(preparation.concentration)"
                    style="width: 300px;float: left"
                    placeholder="请输入浓度">
            <i slot="suffix" style="font-style:normal;margin-right: 10px;color: #333333">%</i>
          </el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12" class="title-bar" id="a02">给药部位与用药途径 <el-button type="text" style="cursor:pointer" @click="clearSiteAndRoute">一键清空</el-button></el-col>
        </el-row>
<!--        <el-row>-->
<!--          <el-col :span="12"><el-button type="text">一键清空</el-button></el-col>-->
<!--        </el-row>-->
        <el-row>
          <el-col :span="10">
            <el-form ref="ppSiteBeanListForm" status-icon :model="ppSiteBeanList" label-width="140px">
              <el-form-item label="给药部位列表" prop="drugComponentQueryStr"
              >
                <!--                :rules="[{ required: true, message: '请输入查询内容', trigger: 'blur'},{ min: 1, max: 30, message: '查询内容长度在 1 到 30 个字符', trigger: 'blur' }]"-->
                <el-select
                  v-model="ppSiteBeanList.drugComponentQueryStr"
                  filterable
                  remote
                  popper-class="select_component"
                  :remote-method="remoteMethod_site"
                  reserve-keyword
                  clearable
                  placeholder="输入给药部位名称"
                  style="width:200px;"
                  @change="(val)=>changeRemote_site(val)"
                  @focus="(val)=>focusRemote_site(val)"
                >
                  <div class="clearfix table_Header">
                    <span style="width:200px;flex:2;">用药部位</span>
                    <span style="width:100px;flex:1;">操作</span>
                  </div>
                  <el-option
                    v-for="(item, index) in siteTableData"
                    :key="item.siteId"
                    :label="item.siteName"
                    :value="item.siteId"
                  >
                    <div class="clearfix spanWrap">
                      <p :title="item.siteName"
                         style="width:200px;flex:2;"
                      >{{ item.siteName }}</p>
                      <p style="width:100px;flex:1;color:#2B60F8;" @click="addSite(index, item)">添加</p>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="10">
            <el-form ref="ppRouteBeanListForm" status-icon :model="ppRouteBeanList" label-width="140px">
              <el-form-item label="用药途径列表" prop="drugComponentQueryStr"
              >
                <!--                :rules="[{ required: true, message: '请输入查询内容', trigger: 'blur'},{ min: 1, max: 30, message: '查询内容长度在 1 到 30 个字符', trigger: 'blur' }]"-->
                <el-select
                  v-model="ppRouteBeanList.drugComponentQueryStr"
                  filterable
                  remote
                  popper-class="select_component"
                  :remote-method="remoteMethod_Route"
                  reserve-keyword
                  clearable
                  placeholder="输入用药途径名称"
                  style="width:200px;"
                  @change="(val)=>changeRemote_Route(val)"
                  @focus="(val)=>focusRemote_Route(val)"
                >
                  <div class="clearfix table_Header">
                    <span style="width:200px;flex:2;">用药途径</span>
                    <span style="width:100px;flex:1;">操作</span>
                  </div>
                  <el-option
                    v-for="(item, index) in routeTableData"
                    :key="item.routeId"
                    :label="item.routeName"
                    :value="item.routeId"
                  >
                    <div class="clearfix spanWrap">
                      <p :title="item.routeName"
                         style="width:200px;flex:2;"
                      >{{ item.routeName }}</p>
                      <p style="width:100px;flex:1;color:#2B60F8;" @click="addRoute(index, item)">添加</p>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 0px 15px 15px;">
          <el-col :span="10" style="margin-right:20px;">
            <el-table
              :data="resultNameOptions"
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :height="400"
              ref="siteForm"
            >
              <el-table-column property="siteName" label="用药部位"></el-table-column>
              <el-table-column label="首选项">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.isDefault" @change="handleSite(scope)">
                    <el-option v-for="item in firstChoiceOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="deleteSiteRow(scope.$index, resultNameOptions)" type="text" style="color: #FF3333;"
                             size="small"
                  >删除
                  </el-button>
                  <el-button v-if="scope.row.isDefault === true" type="text" size="small" style="color: #1e1e1e"
                             disabled
                  >默认推荐值
                  </el-button>
                  <el-button v-else-if="scope.row.isDefault === false"
                             @click="setSiteRow(scope.$index, resultNameOptions)" type="text" size="small"
                  >设为推荐值
                  </el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
          <el-col :span="10">
            <el-table
              :data="resultNameOptions02"
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :height="400"
              ref="routeForm"
            >
              <el-table-column property="routeName" label="用药途径"></el-table-column>
              <el-table-column label="首选项">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.isDefault" @change="handleRoute(scope)">
                    <el-option v-for="item in firstChoiceOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="deleteRouteRow(scope.$index, resultNameOptions02)" type="text"
                             style="color: #FF3333;" size="small"
                  >删除
                  </el-button>
                  <el-button v-if="scope.row.isDefault === true" type="text" size="small" style="color: #1e1e1e"
                             disabled
                  >默认推荐值
                  </el-button>
                  <el-button v-else-if="scope.row.isDefault === false"
                             @click="setRouteRow(scope.$index, resultNameOptions02)" type="text" size="small"
                  >设为推荐值
                  </el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="title-bar" id="a03">制剂规格列表</el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button type="primary" style="text-align:right;background: #2B60F8" @click="addGg">
              添加规格
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="padding-bottom:20px;">
            <el-table
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="tableData"
              ref="tableData"
              style="width: 100%"
            >
              <el-table-column
                label="规格ID"
                prop="specId"
                sortable
                width="120"
              >
                <template slot-scope="scope">
                  <span style="margin-right: 5px;">{{ scope.row.specId }}-{{ scope.row.id }}</span>
                  <span v-for="item in scope.row.drugCategoryList"
                        :key="item.id"
                  >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="showSpec"
                sortable
                label="规格"
              >
              </el-table-column>
              <el-table-column
                prop="isDefault"
                label="首选项"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.isDefault === true">是</span>
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column
                prop="descList"
                sortable
                label="规格描述"
              >
                <template slot-scope="scope">
                  <div v-for="item in scope.row.descList" :key="item.id">
                    <div v-if="item.isDefault===true">
                      <span>{{ item.specDesc }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="启用" align="center" key="status" width="120">
                <template slot-scope="scope">
                  <el-switch
                    class="switch"
                    active-color="#5DB730"
                    active-text="启用"
                    inactive-text="停用"
                    inactive-color="#B5CCF5"
                    v-model="scope.row.status"
                    active-value="0"
                    inactive-value="1"
                    @change="handleStatusChange(scope.row, scope.$index)"
                  ></el-switch>
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="300"
                               fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="updateSpec(scope.row, scope.$index)"
                  >修改
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    @click="copydateSpec(scope.row, scope.$index)"
                  >复制
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    style="color: #FF3333;"
                    @click="deleteSpec(scope.row, scope.$index)"
                  >删除
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    @click="handleSource(scope.row, scope.$index)"
                  >来源
                  </el-button>
                  <el-button
                    v-if="scope.row.id"
                    size="mini"
                    type="text"
                    @click="setDrugProd(scope.row, scope.$index)"
                  >新增药品
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    @click="editSpecIngredient(scope.row, scope.$index)"
                  >成分
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    @click="copySpec(scope.row)"
                  >创建同规格
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    @click="$refs.drugCategoryEdit.refreshDrugCategoryTree(scope.row.id,5)"
                  >分类维护
                  </el-button>
                  <el-button
                    v-if="scope.row.isDefault === true"
                    size="mini"
                    type="text"
                    style="color: #1e1e1e"
                    disabled
                  >默认首选规格
                  </el-button>
                  <el-button
                    v-else-if="scope.row.isDefault === false"
                    size="mini"
                    type="text"
                    @click="setSpecPrimary(scope.row, scope.$index)"
                  >设为首选规格
                  </el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="20" class="bottom-bar" style="height: 80px;padding-right: 0px;position: fixed;right: 30px;bottom: 10px;">
        <el-button style="float: right;margin-left: 10px;" @click="editNext" v-show="preparation.preparationId">下一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="editPrev"  v-show="preparation.preparationId">上一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="returnPage">返回</el-button>
        <el-button style="float: right;display: none" @click="resetForm">重置</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(1)">保存</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(2)">保存并关闭</el-button>
      </el-col>
    </el-row>
    <el-dialog v-dialogDrag title="药物检索" :visible.sync="drugComponent.drugComponentTableVisible">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="drugComponent.drugComponentTableData"
        :height="300"
      >
        <el-table-column property="id" label="药物ID" width="150"></el-table-column>
        <el-table-column property="name" label="药物名称"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini" type="primary" icon="el-icon-success"
              @click="choosedrugComponentData(scope.$index, scope.row)"
            >选择
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog v-dialogDrag title="引用来源管理" :visible.sync="resourceTableVisible">
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableForm">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableForm.list"
        >
          <el-table-column property="date" label="引用资源类型">
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
                :rules="[{ required: true, message: '请选择药物名称', trigger: 'blur'}]"
              >
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  size="small"
                  style="width: 200px"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column property="name" label="引用资源名称">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width: 200px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="'resourceDataOption'+item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="200">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;"
                     size="small" @click="saveResource"
          >保存
          </el-button>
          <el-button style="float: right;margin-right: 10px;"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog v-dialogDrag title="成分编辑" append-to-body width="60%" :visible.sync="addSpecIngredientDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row style="padding: 20px;">
        <el-form ref="addSpecIngredientForm" status-icon :model="specIngredientForm" label-width="120px"
        >
          <el-row>

            <el-col :span="14">
              <el-form-item label="成分物质:" prop="substanceId"
                            :rules="specIngredientFormRule.substanceId"
              >
                <el-select
                  v-model="specIngredientForm.substanceId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词搜索"
                  :remote-method="querySubstanceName"
                  :loading="loading"
                  style="width: 280px;float: left"

                >
                  <el-option
                    v-for="item in substanceList"
                    :key="'substanceList'+item.substanceId"
                    :label="item.name"
                    :value="item.substanceId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="成分类型:" prop="ingredientType">
                <el-select v-model="specIngredientForm.ingredientType"
                           style="width: 150px;float: left"
                >
                  <el-option
                    v-for="item in drugIngredientTypeDict"
                    :key="'drugIngredientTypeDict'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>

            <el-col :span="14">
              <el-form-item label="物质数量:" prop="substanceQtyMax">
                <el-input-number v-model="specIngredientForm.substanceQtyMax" :min="0" controls
                                 controls-position="right" :precision="4" style="width: 150px;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="物质数量单位:" prop="substanceQtyUnitId">
                <el-select v-model="specIngredientForm.substanceQtyUnitId"
                           style="width: 150px;float: left"
                           filterable
                           :filter-method="substanceQtyUnitFilter"
                           @focus="quantityUnitDictOptions=quantityUnitDict"
                >
                  <el-option label="" value></el-option>
                  <el-option
                    v-for="item in quantityUnitDictOptions"
                    :key="'quantityUnitDict'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="物质参考说明:" prop="substanceReferenceDesc"
              >
                <el-input type="textarea" :rows="6" placeholder="请输入物质参考说明"
                          v-model="specIngredientForm.substanceReferenceDesc"
                          maxlength="200" clearable show-word-limit
                          style="width: 500px;float: left;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-button type="primary" size="small"
                         style="margin-left: 20px;background: #2B60F8;float:right;"
                         @click="confirmSpecIngredient"
              >确定
              </el-button>
              <el-button style="float: right;margin-right: 10px;"
                         size="small" @click="cancelSpecIngredient"
              >取消
              </el-button>
            </el-col>
          </el-row>


        </el-form>
      </el-row>
    </el-dialog>

    <AddSpecification v-if="isAddSF"
                      :visible="isAddSF"
                      :preparationId="preparationId"
                      :specMode="specMode"
                      :specData="specData"
                      :isEdit="isEdit"
                      :specModeChoice="specModeChoice"
                      :specTableData="tableData"
                      :drugCompositionList="drugCompositionList"
                      @tableValue="tableValue"
                      @closeAddSF="closeAddSF"/>
    <drug-category-edit ref="drugCategoryEdit"></drug-category-edit>
    <edit-ingredient
      v-if="isEditIngredient"
      :EditDialogVisible="isEditIngredient"
      :singleSpecId="singleSpecId"
      :specId="specId"
      :ingredientData="ppSpecIngredientList"
      @closeEditIngredient="closeEditIngredient"
      @getIngredientList="getIngredientList">
    </edit-ingredient>
  </el-container>
</template>
<script>
import { info } from '@/api/dkm/drug'
import { selectDrugNameList } from '@/api/dkm/drugGroup'
import { getResourceCategory } from '@/utils/resourceReference'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import AddSpecification from '@/components/AddSpecification'
import { dict,preparationList } from '@/api/dkm/preparationSpecification'
import { DEEP_CLONE } from '@/utils/constants'
import { resourceNameQuery } from '@/api/dkm/resource'
import { getFormConceptTree } from '@/utils/concept'
import { selectSubstanceNameList } from '@/api/dkm/substance'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/utils/nav.js'
import { isEmpty } from '@/utils/common'
import AcrossUtil from '@/utils/acrossUtil'
import {
  siteEnable,
  routeEnable,
  preparationUpdata,
  getpreparationInfo,
  delSpec,
  statusSpec,
  defaultSpec,
  selectAdminRoute,
  selectAppSite,
  checkFields
} from '@/api/dkm/preparationSpecification'
import {
  PREPARATION_DATA
} from '@/utils/editPreparation'
import {
  PPSPECBEANLIST_DATA
} from '@/utils/addSpecification'
import tableConst from '@/const/tableConst'
import $ from 'jquery'
import { setCache, getCache } from '@/utils/cacheUtil'
import EditIngredient from "./EditIngredient";
export default {
  components: { AddSpecification, Treeselect, EditIngredient },
  name: 'preparationSpecificationEdit',
  data() {
    return {
      conceptConst,
      preparationId: '', // 制剂ID
      drugComponentTableData: [],
      siteTableData: [],
      routeTableData: [],
      /*选择剂型分类树结构数据*/
      selectFormTreeData: [],
      selectFormTreeShow: true,
      tableConst,
      isEdit: false,
      isAddSF: false,
      specMode: '1',
      specData: {},
      tableData: [],
      drugGroupName: '',
      injuryAndPoisonCode: [],
      resultNameOptions: [], // 已选择给药部位list
      resultNameOptions02: [], // 已选择用药途径list
      dpSpecIngredientList: [], // 成分list
      loading: false,
      preparation: DEEP_CLONE(PREPARATION_DATA),
      // 给药部位列表
      ppSiteBeanList: {
        /*药物组检索输入框数据*/
        drugComponentQueryStr: null,
        /*药物组检索表格数据*/
        drugGroupTableData: [],
        /*药物组检索表格弹是否显示*/
        drugGroupTableVisible: false
      },
      // 用药途径列表
      ppRouteBeanList: {
        /*药物组检索输入框数据*/
        drugComponentQueryStr: null,
        /*药物组检索表格数据*/
        drugGroupTableData: [],
        /*药物组检索表格弹是否显示*/
        drugGroupTableVisible: false
      },
      drugComponent: {
        /*药物成分检索输入框数据*/
        drugComponentQueryStr: null,
        /*药物成分检索表格数据*/
        drugComponentTableData: [],
        /*药物成分检索表格弹是否显示*/
        drugComponentTableVisible: false
      },
      /*药物类型下拉列表*/
      substanceTypeOptions: [],
      /*中西药标识符下拉列表 */
      cwMedicineIdentification: [],
      /**药物成分类型下拉列表 */
      drugComponentTypeOptions: [],
      /*语言概念下拉列表*/
      languageConcep: [],
      /*药物属性名称概念字典*/
      substanceProps: [],
      /*药物属性值概念字典*/
      substancePropsVal: {},
      /*记录每个属性名称选中项，用于控制关联的属性值下拉列表的值*/
      propsNameValSelectOpts: {},
      // resourceTypeOption: [{ id: 1, typeName: '说明书' }, { id: 2, typeName: '药典' }],
      // resourceDataOption: [],
      // /* 控制引用来源表格弹窗显示隐藏*/
      // resourceTableVisible: false,
      // /* 来源引用表格数据*/
      // resourceTableData:{
      //   list:[]
      // },
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /*来源类型下拉列表*/
      resourceTypeOption: [],
      resourceDataOption: [],
      /*当前操作表格行数据*/
      editTableRow: [],
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      /* 当前编辑来源引用弹窗临时记录信息*/
      nowEditResourceData: {
        sourceTable: null,
        sourceTableField: null,
        sourceTableId: null
      },
      preparationTypeDict: [], // 剂型字典
      preparationTypeList: [], // 剂型list
      drugTypeSpecsList: [], // 中药类别与规格
      drugProcMethod: [], // 中药炮制方法
      firstChoiceOptions: [
        { label: '是', value: true },
        { label: '否', value: false }
      ],
      oldType: '', // 上次选择剂型
      newType: '', // 当前选择剂型
      isChoiceType: false, //是否选择剂型
      specIndex: null, // 规格index
      specGroupKey: null, // 创建同规格
      classify: '', // 药物类型 100403 草药
      specModeChoice: false,
      flag: 0,
      menulist:[
        {
         href: '#a01',
         name: '制剂基本信息'
        },
        {
          href: '#a02',
          name: '给药部位与用药途径'
        },
        {
          href: '#a03',
          name: '制剂规格列表'
        }
      ],
      /*名称类型下拉列表*/
      aliasTypeConcept: [],
      // specIndex: '' // 规格ID
      /*列表页面查询参数*/
      queryParams:{},
      /*存储根据查询获取到的数据，用于翻页*/
      pageData:[],
      /*添加药品成分弹窗*/
      addSpecIngredientDialogVisible: false,
      /*添加/修改药品成分表单*/
      specIngredientForm: {},
      /*添加/修改药品成分表单校验规则*/
      specIngredientFormRule: {
        substanceId: [{ required: true, message: '请选择成分物质', trigger: 'blur' }],
        quantityType: [],
        denominatorQty: [],
        denominatorQtyUnitId: []
      },
      /*修改成分弹窗--名称下拉列表数据*/
      substanceList: [],
      /*药物成分类型字典*/
      drugIngredientTypeDict: [],
      quantityUnitDictOptions: [],
      /*药品成分*/
      dkbDpSpecIngredientList: [],
      isEditIngredient: false, // 是否修改规格中的成分
      specId: '',
      singleSpecId: '',
      ppSpecIngredientList: [],
      drugCompositionList: [] // 选择药物之后对应的药物成分集合
    }
  },
  watch: {
    'preparation.type': function(newValue, oldValue) {
      // console.log(newValue, oldValue)
      this.oldType = oldValue
      this.newType = newValue
    }
  },
  mounted: function() {

  },
  async created() {
    this.loading=true
    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })
    const id = this.$route.params && this.$route.params.id
    if (id) {
      this.queryParams=getCache("preparationQueryParams")
      this.pageData=getCache("preparationPageData")
      if(!this.queryParams){
        this.queryParams={}
      }
      if(!this.queryParams.pageNum){
        this.queryParams.pageNum=1
      }
      if(!this.queryParams.pageSize){
        this.queryParams.pageSize=10
      }

      this.init().then(result => {
        this.getDrugInfo(id)
      })
    } else {
      this.init().then(result => {
        this.addSubstanceName('中文')
        this.loading=false
      })
      if (this.$route.query.drugData !== undefined) {
        let drugData = JSON.parse(this.$route.query.drugData)
        // console.log(this.$route.query.drugData)
        // let row = this.$route.query.drugData
        this.preparation.drugName = drugData.name
        this.preparation.drugId = drugData.id
        this.classify = drugData.classify
      }
    }
    /*获取剂型字典*/
    getFormConceptTree().then(result => {
      this.selectFormTreeData = result
    })

    this.scrollFn()

  },
  computed: {
    /*计算属性--获取cas编码和unii编码*/
    substanceCode() {
      let code = {}
      this.preparation.dkbSubstanceCode.some((item, i) => {
        if (item.codeSysName === 'unii') {
          code.unii = item.substanceCode
        }
        if (item.codeSysName === 'cas') {
          code.cas = item.substanceCode
        }
        if (code.unii && code.cas) {
          return true
        }

      })
      return code
    }
  },
  methods: {
    // 关闭成分弹出层
    closeEditIngredient(val){
      this.isEditIngredient = val
    },
    // 修改或者添加成分list
    getIngredientList(val) {
      this.isEditIngredient = false
      this.tableData[this.specIndex].ppSpecIngredientList = val
    },
    // 一键清空部位和途径列表数据
    clearSiteAndRoute() {
      this.resultNameOptions = []
      this.resultNameOptions02 = []
    },
    chooseMenu(index) {
      this.flag = index
    },
    scrollFn() {
      $(document).ready(function () {
        // var navHeight = $(".inside-menu-wrap").offset().top;
        // console.log(navHeight)
        var navHeight = $(".inside-menu-wrap").offset().top;
        // console.log(navHeight)
        var navFix=$(".inside-menu-wrap");
        $(window).scroll(function () {
          if ($(this).scrollTop() >= navHeight) {
            // console.log($(this).scrollTop())
            navFix.addClass("menuFix");

          } else {
            navFix.removeClass("menuFix");
            $(".inside-menu li a.active").removeClass("active")
          }
        })
      })

     //内容信息导航锚点
      $('.inside-menu-wrap').navScroll({
        scrollSpy: true
      });
    },
    // 剂型变化更新部位和途径
    async changeType(node, instanceId) {
      let id=node.conceptId
      // 2022-04-21 移除这部分逻辑，不自动填充给药途径和部位
      if (id) {
        // let resRoute = await selectAdminRoute(id)
        let resSite = await selectAppSite(id)
        if (this.resultNameOptions.length !== 0 && this.isChoiceType === false) {
          // this.$confirm('给药部位或者用药途径列表中已存在数据，该操作数据会被刷新清除，是否继续操作？', '警告', {
            this.$confirm('给药部位列表中已存在数据，该操作数据会被刷新清除，是否继续操作？', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.isChoiceType = true
            this.motifylist(resSite)
            this.preparation.type = this.newType
          }).catch(() => {
            this.isChoiceType = false
            this.preparation.type = this.oldType
          })
        } else {
          // 切换剂型
          this.isChoiceType = true
          this.motifylist(resSite)
        }
      } else {
        this.resultNameOptions = []
        this.resultNameOptions02 = []
      }
      // this.resultNameOptions = []
      // this.resultNameOptions02 = []
    },
    // 修改制剂规格
    motifylist(resSite) {
      // console.log(resSite, resRoute)
      this.resultNameOptions = []
      this.resultNameOptions02 = []
      resSite.data.forEach((item) => {
        let site = {}
        site.siteName = item.appSiteConceptDesc
        site.siteId = item.appSiteConceptId
        site.isDefault = false
        site.id = ''
        site.preparationId = ''
        this.resultNameOptions.push(site)
      })
      // resRoute.data.forEach((item) => {
      //   let route = {}
      //   route.routeName = item.adminRouteConceptDesc
      //   route.routeId = item.adminRouteConceptId
      //   route.isDefault = false
      //   route.id = ''
      //   route.preparationId = ''
      //   this.resultNameOptions02.push(route)
      // })
      // console.log(this.resultNameOptions02)
      // this.$nextTick(() => {
      //   this.$refs.routeForm.doLayout()
      // })
      // console.log(this.resultNameOptions)
      this.$nextTick(() => {
        this.$refs.siteForm.doLayout()
      })
    },
    async getList(value, query) {
      // let obj = {}
      // obj.domainCode = value
      // obj.searchValue = query
      let res = await dict(value, query)
      return res.data
    },
    closeAddSF() {
      this.isAddSF = false
    },
    tableValue(val) {
      // console.log(val)
      // console.log(this.specIndex)
      // console.log(typeof this.specIndex)
      if (this.specIndex !== null && this.specIndex !== undefined && this.specIndex !== '') {
        // console.log(this.specIndex)
        this.tableData[this.specIndex] = val
        // console.log(this.tableData[this.specIndex])
      } else {
        // console.log("*****************specGroupKey****************")
        // console.log(this.specGroupKey)
        if (this.specGroupKey !== null && this.specGroupKey !== undefined && this.specGroupKey !== '') {
          val.specGroupKey = this.specGroupKey
          val.isDefault = false
        } else {
          val.ppSpecIngredientList = this.drugCompositionList
          console.log(this.drugCompositionList)
          this.ppSpecIngredientList = this.drugCompositionList
        }
        this.tableData.push(val)
        // console.log("****************tableData*****************")
        // console.log(this.tableData)
      }
      this.$nextTick(() => {
        this.$refs.tableData.doLayout()
      })
    },
    // 添加规格
    addGg() {
      this.specGroupKey = null
      this.isEdit = false
      this.isAddSF = true
      this.specData = DEEP_CLONE(PPSPECBEANLIST_DATA)
      // console.log(this.preparationId)
      if (this.preparation.concentration !== null && this.preparation.concentration !== undefined && this.preparation.concentration !== '') {
        // this.specData.concentration = this.preparation.concentration
        // this.specData.maxConcentration = this.preparation.concentration
        this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
        // this.specModeChoice = true
        this.specModeChoice = false
      } else {
        this.specData.concentration = null
        this.specData.maxConcentration = null
        this.specMode = '1'
        this.specModeChoice = false
      }
      this.specIndex = null
    },
    // 修改规格
    updateSpec(row, index) {
      // console.log(row)
      // let rows = DEEP_CLONE(row)
      this.specMode = row.specMode.toString()
      this.$nextTick(() => {
        this.specData = row
       /* if (this.preparation.concentration !== null && this.preparation.concentration !== undefined && this.preparation.concentration !== '') {
          this.specData.concentration = this.preparation.concentration
          this.specData.maxConcentration = this.preparation.concentration
          this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
          this.specModeChoice = true
        } else {
          this.specData.concentration = null
          this.specData.maxConcentration = null
          // this.specMode = '1'
          this.specModeChoice = false
        }*/
      })
      this.isAddSF = true
      this.isEdit = true
      this.specIndex = index
      // console.log(index)
      // console.log(this.specIndex)
    },
    // 复制规格
    copydateSpec(row) {
      // console.log(row)
      this.specMode = row.specMode.toString()
      this.specData = DEEP_CLONE(row)
      this.isAddSF = true
      this.isEdit = false
      this.specIndex = null
      this.specData.specGroupKey = Math.random().toString()
      this.specData.specId = null
      this.specData.id = null
      this.specData.descList.forEach((item) => {
        item.id = null
        item.specId = null
      })
      if (this.preparation.concentration !== null && this.preparation.concentration !== undefined && this.preparation.concentration !== '') {
        // this.specData.concentration = this.preparation.concentration
        // this.specData.maxConcentration = this.preparation.concentration
        this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
        // this.specModeChoice = true
        this.specModeChoice = false
      } else {
        this.specData.concentration = null
        this.specData.maxConcentration = null
        // this.specMode = '1'
        this.specModeChoice = false
      }
    },
    // 创建同规格
    copySpec(row) {
      this.specGroupKey = row.specGroupKey
      this.isEdit = false
      this.isAddSF = true
      this.specData = DEEP_CLONE(PPSPECBEANLIST_DATA)
      if (this.preparation.concentration !== null && this.preparation.concentration !== undefined && this.preparation.concentration !== '') {
        // this.specData.concentration = this.preparation.concentration
        // this.specData.maxConcentration = this.preparation.concentration
        this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
        // this.specModeChoice = true
        this.specModeChoice = false
      } else {
        this.specData.concentration = null
        this.specData.maxConcentration = null
        // this.specMode = '1'
        this.specModeChoice = false
      }
      // console.log(this.preparationId)
      this.specIndex = null
    },
    deleteSiteRow(index, rows) {
      rows.splice(index, 1)
    },
    deleteRouteRow(index, rows) {
      rows.splice(index, 1)
    },
    handleSite(config) {
      // console.log(config)
      if (config.row.isDefault === true) {
        this.resultNameOptions.forEach((item) => {
          item.isDefault = false
        })
        let index = config.$index
        this.resultNameOptions[index].isDefault = true
      }
    },
    setSiteRow(index, rows) {
      // console.log(index, rows)
      rows.forEach((item) => {
        item.isDefault = false
      })
      if (rows[index].isDefault === false) {
        rows[index].isDefault = true
      }
    },
    handleRoute(config) {
      // console.log(config)
      if (config.row.isDefault === true) {
        this.resultNameOptions02.forEach((item) => {
          item.isDefault = false
        })
        let index = config.$index
        this.resultNameOptions02[index].isDefault = true
      }
    },
    setRouteRow(index, rows) {
      // console.log(index, rows)
      rows.forEach((item) => {
        item.isDefault = false
      })
      if (rows[index].isDefault === false) {
        rows[index].isDefault = true
      }
    },
    /*规格状态修改*/
    handleStatusChange(row, index) {
      // console.log(row)
      const id = row.id
      let that = this
      if (id) {
        let text = row.status === '0' ? '启用' : '停用'
        this.$confirm('确认要"' + text + '"ID为"' + row.id + '"的规格吗?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function() {
          return statusSpec(row.id, row.status)
        }).then(() => {
          this.msgSuccess(text + '成功')
        }).catch(function() {
          row.status = row.status === '0' ? '1' : '0'
        })
      } else {
        let text = row.status === '0' ? '启用' : '停用'
        this.$confirm('确认要"' + text + '"index为"' + index + '"的规格吗?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.msgSuccess(text + '成功')
          this.$nextTick(() => {
            this.$refs.tableData.doLayout()
          })
        }).catch(function() {
          this.tableData[index].status = this.tableData[index].status === '0' ? '1' : '0'
        })
      }
    },
    /** 删除规格按钮操作 */
    deleteSpec(row, index) {
      // console.log(row)
      const id = row.id
      let that = this
      if (id) {
        this.$confirm('是否删除编号为"' + id + '"的规格?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let obj = {}
          obj.id = id
          delSpec(obj)
        }).then(() => {
          // this.selectSubstanceList()
          this.msgSuccess('删除成功')
          this.$nextTick(() => {
            this.$refs.tableData.doLayout()
          })
          const id = this.$route.params && this.$route.params.id
          // console.log(id)
          if (id) {
            this.init().then(result => {
              this.getDrugInfo(id)
            })
          }
        }).catch(function() {
        })
      } else {
        this.$confirm('是否删除indx为"' + index + '"的规格?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // console.log(this.tableData)
          this.tableData.splice(index, 1)
          // console.log(this.tableData)
          this.msgSuccess('删除成功')
          this.$nextTick(() => {
            this.$refs.tableData.doLayout()
          })
        }).catch(function() {

        })
      }
    },
    // 设首选规格
    setSpecPrimary(row, index) {
      // console.log(row)
      const id = row.id
      let that = this
      this.$confirm('是否设置index为"' + index + '"的规格为首选项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableData.forEach((item) => {
          if (item.specGroupKey === row.specGroupKey) {
            item.isDefault = false
          }
        })
        this.tableData[index].isDefault = true
        this.$nextTick(() => {
          this.$refs.tableData.doLayout()
        })
        this.msgSuccess('设置成功')
      }).catch(function() {

      })
    },
    /* 剂型--下拉搜索过滤*/
    typeFilter(query) {
      if (query !== '') {
        this.preparationTypeList = this.preparationTypeDict.filter(item => {
          return item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
        })

      } else {
        this.preparationTypeList = this.preparationTypeDict
      }
    },
    // 中药类别与规格
    drugTypeSpecsFilter(query) {
      if (query !== '') {
        this.preparationTypeList = this.preparationTypeDict.filter(item => {
          return item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
        })

      } else {
        this.preparationTypeList = this.preparationTypeDict
      }
    },
    /*初始化--加载字典等*/
    async init() {
      //获取剂型名称数据字典
      await getConcept(conceptConst.DKB_PP_FORM, 0).then(result => {
        this.preparationTypeDict = result
        this.preparationTypeList = result
      })
      //获取制剂名称数据字典
      await getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
        // this.substanceTypeOptions = result
        this.cwMedicineIdentification = result
      })
      /*获取语言概念字典*/
      await getConcept(conceptConst.PUB_LANG, 0).then(result => {
        this.languageConcep = result
      })
      /*获取药物属性概念字典*/
      await getConcept(conceptConst.DKB_SUBSTANCE_PROPS, 1).then(result => {
        this.substanceProps = result
        let propsVal = this.substancePropsVal
        result.forEach(function(item, index) {
          propsVal[item.conceptId] = item.sonDictTermConcept
        })
      })

      // /*获取药物成分类型字典*/
      // await getConcept(conceptConst.DKB_DRUG_INGREDIENT_TYPE, 0).then(result => {
      //   this.drugComponentTypeOptions = result
      // })
      /*获取名称类型字典*/
      await getConcept(conceptConst.PUB_NAME_TYPE, 0).then(result => {
        this.aliasTypeConcept = result
      })
      /*获取药物成分类型字典*/
      await getConcept(conceptConst.DKB_DRUG_INGREDIENT_TYPE, 0).then(result => {
        this.drugIngredientTypeDict = result
      })
      /*获取数量类型字典*/
      await getConcept(conceptConst.PUB_MATH_OPERATOR, 0).then(result => {
        this.quantityTypeDict = result
      })
      /*获取质量单位字典*/
      await getConcept(conceptConst.PUB_MASS_UNIT, 0).then(result => {
        this.quantityUnitDict = result
        this.quantityUnitDictOptions = result
      })

    },
    /*添加制剂名称输入表单组件*/
    addSubstanceName(type) {
      let defaultVal
      if (this.languageConcep && this.languageConcep.length > 0) {
        this.languageConcep.forEach(item => {
          if (item.conceptVal == type) {
            defaultVal = item.conceptId
          }
        })
      }
      this.preparation.preparationAliasList.push({
        preparationAlias: '',
        aliasType: type=='英语'?conceptConst.PUB_NAME_TYPE_EN:conceptConst.PUB_NAME_TYPE_GENERIC,
        languageId: defaultVal,
        isDefault: this.preparation.preparationAliasList.length == 0 ? true : false
      })
      if (this.preparation.preparationAliasList.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.preparation.namePrimary = 0
      }

    },
    /*移除制剂名称输入表单组件*/
    delSubstanceName(item) {
      var index = this.preparation.preparationAliasList.indexOf(item)
      if (this.preparation.preparationAliasList.length > 1) {
        this.preparation.preparationAliasList.splice(index, 1)
      } else {
        this.$message('制剂名称至少保留一个！')
      }
      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      this.preparation.preparationAliasList.forEach(function(item, index) {
        if (item.isDefault === true) {
          primary = index
          return
        }
      })
      if (primary === -1) {
        //删除后没有默认值了
        this.preparation.preparationAliasList[0].isDefault = true
        this.preparation.namePrimary = 0
      }

    },
    /** 保存按钮--新增制剂 */
    submitForm(type) {
      this.$refs['editForm'].validate(valid => {
        if (valid) {
          if (this.preparation.drugId === '' || this.preparation.drugId === undefined || this.preparation.drugId === null) {
            this.msgError('所属药物不能为空')
            return
          }
          // if (this.preparation.ppSiteBeanList.length === 0) {
          //   this.msgError('给药部位列表不能为空')
          //   return
          // }
          // if (this.preparation.ppRouteBeanList.length === 0) {
          //   this.msgError('用药途径列表列表不能为空')
          //   return
          // }
          this.preparation.ppSiteBeanList = this.resultNameOptions
          this.preparation.ppRouteBeanList = this.resultNameOptions02
          this.preparation.ppSpecBeanList = this.tableData
          preparationUpdata(this.preparation).then(response => {
            this.msgSuccess(response.msg)
            //调用刷新表格数据的方法
            AcrossUtil.$emit('tableReload');
            const id = this.$route.params && this.$route.params.id
            // console.log(id)
            if (id) {
              this.init().then(result => {
                this.getDrugInfo(id)
              })
            }
            if(type==2){
              //this.returnPage()
              this.$store.dispatch('tagsView/delView', this.$route)
              this.$router.push('/dkm/preparationSpecification')
            }else{
              if(response.data){
                //保存完成后根据id跳转到编辑界面
                this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
                  //this.$router.push('/preparationSpecification/edit/' + response.data)
                  this.$nextTick(() => {
                    this.$router.replace({
                      path: '/redirect' + '/preparationSpecification/edit/' + response.data
                    })
                  })
                })
              }
            }
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },

    /*返回制剂列表页面，刷新*/
    returnPage() {
      const id = this.$route.params && this.$route.params.id
      if (id) {

        this.init().then(result => {
          this.getDrugInfo(id)
        })
      } else {
        this.init()
        this.clearData()
      }
      this.$store.dispatch('tagsView/delView', this.$route)
      this.$store.dispatch('tagsView/delCachedView', {name: "preparationSpecification"}).then(res => {
        this.$router.push('/dkm/preparationSpecification')
      })
    },
    clearData() {
      this.$nextTick(() => {
        if (this.$refs.editForm !== undefined) {
          this.$refs.editForm.resetFields()
        }
        if (this.$refs.drugComponentForm !== undefined) {
          this.$refs.drugComponentForm.resetFields()
        }
        if (this.$refs.ppSiteBeanListForm !== undefined) {
          this.$refs.ppSiteBeanListForm.resetFields()
        }
        if (this.$refs.ppRouteBeanListForm !== undefined) {
          this.$refs.ppRouteBeanListForm.resetFields()
        }
        this.resultNameOptions = []
        this.resultNameOptions02 = []
        this.preparation = DEEP_CLONE(PREPARATION_DATA)
        this.tableData = []
        this.addSubstanceName()
      })
    },
    /*根据药物id获取药物明细*/
    getDrugInfo(id) {
      // console.log(id)
      var that = this
      getpreparationInfo(id).then(response => {
        //console.log(response)
        // let drugId = response.data.drugId
        // let drugName = response.data.drugName
        // if (drugName && drugName.trim() !== '') {
        //   let obj = {}
        //   obj.queryStr = drugName
        //   selectDrugNameList(obj).then(res => {
        //     console.log(res.data)
        //     let row = {}
        //     res.data.map((item) => {
        //       if (drugId === item.id) {
        //         row = item
        //       }
        //     })
        //     console.log(row)
        //     this.preparation.drugName = row.name
        //     this.preparation.drugId = row.id
        //     this.classify = row.classify
        //     // this.drugComponentTableData
        //   })
        // }
        if (response.data.drugTypeSpecsBean) {
          let Specs = {}
          this.drugTypeSpecsList = []
          Specs.code = response.data.drugTypeSpecsBean.conceptId
          Specs.name = response.data.drugTypeSpecsBean.conceptVal
          this.drugTypeSpecsList.push(Specs)
        }
        if (response.data.drugProcMethodBean) {
          let Method = {}
          this.drugProcMethod = []
          Method.code = response.data.drugProcMethodBean.conceptId
          Method.name = response.data.drugProcMethodBean.conceptVal
          this.drugProcMethod.push(Method)
        }
        if (response.data.drugBean) {
          this.preparation.drugName = response.data.drugBean.name
          this.preparation.drugId = response.data.drugBean.id
          this.classify = response.data.drugBean.classify
        }
        // this.preparation.drugName = response.data.drugName
        // this.preparation.drugId = response.data.drugId
        this.preparation = response.data
        if (this.preparation.ppSiteBeanList === null) {
          this.resultNameOptions = []
        } else {
          this.resultNameOptions = this.preparation.ppSiteBeanList
        }
        if (this.preparation.ppRouteBeanList === null) {
          this.resultNameOptions02 = []
        } else {
          this.resultNameOptions02 = this.preparation.ppRouteBeanList
        }
        if (this.preparation.ppSpecBeanList === null) {
          this.tableData = []
        } else {
          this.tableData = this.preparation.ppSpecBeanList
        }
        if (response.data.dpSpecIngredientList && response.data.dpSpecIngredientList.length>=0) {
          this.dpSpecIngredientList = response.data.dpSpecIngredientList
        }
        // this.resultNameOptions = this.preparation.ppSiteBeanList
        // this.resultNameOptions02 = this.preparation.ppRouteBeanList
        // this.tableData = this.preparation.ppSpecBeanList
        this.preparationId = this.preparation.preparationId.toString()
        // console.log(this.resultNameOptions)
        // console.log(this.resultNameOptions02)
        this.setPrimary('name')
        this.loading=false
      })
    },
    remoteMethod_drug(val) {
      // console.log(val)
      if (val && val.trim() !== '') {
        let obj = {}
        obj.queryStr = val
        selectDrugNameList(obj).then(response => {
          this.drugComponentTableData = response.data
        })
      }
    },
    // 获取药品对应药物成分
    async changeRemote_drug(val) {
      // console.log(val)
      if (val) {
        await this.getDrugComposition(val)
      } else {
        this.drugCompositionList = []
      }
      // this.classify = val
    },
    delDrug() {
      // console.log('删除')
      this.preparation.drugName = ''
      this.preparation.drugId = ''
      this.classify = ''
    },
    // 中药类别与规格
    remoteMethod_drugTypeSpecs(queryString) {
      // console.log(queryString)
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          dict(conceptConst.DKB_TCM_CLASS_SPEC, queryString.trim()).then(response => {
            // console.log(response)
            this.drugTypeSpecsList = response.data
          })
        }, 200)
      }
    },
    // 中药炮制方法
    remoteMethod_drugProc(queryString) {
      // console.log(queryString)
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          dict(conceptConst.DKB_TCM_PROC_METHOD, queryString.trim()).then(response => {
            // console.log(response)
            this.drugProcMethod = response.data
          })
        }, 200)
      }
    },
    remoteMethod_site(val) {
      // console.log(val)
      // if (val && val.trim() !== '') {
        // console.log(this.resultNameOptions)
        let arr = []
        this.resultNameOptions.forEach((item) => {
          arr.push(item.siteId)
        })
        // console.log(arr)
        let obj = {}
        obj.searchVal = val
        obj.ignoreIds = arr.toString()
        siteEnable(obj).then(response => {
          // console.log(response)
          this.siteTableData = response.data
        })
      // }
    },
    changeRemote_site(val) {
      // console.log(val)
    },
    focusRemote_site(val) {
      // console.log(val)
      this.remoteMethod_site(val)
    },
    remoteMethod_Route(val) {
      // console.log(val)
      // if (val && val.trim() !== '') {
        let arr = []
        this.resultNameOptions02.forEach((item) => {
          arr.push(item.routeId)
        })
        // console.log(arr)
        let obj = {}
        obj.searchVal = val
        obj.ignoreIds = arr.toString()
        routeEnable(obj).then(response => {
          // console.log(response)
          this.routeTableData = response.data
        })
      // }
    },
    changeRemote_Route(val) {
      // console.log(val)
    },
    focusRemote_Route(val) {
      this.remoteMethod_Route(val)
    },
    /*药物检索表格，选中数据*/
    addDrug(index, row) {
      // console.log(index, row)
      this.preparation.drugName = row.name
      this.preparation.drugId = row.id
      this.classify = row.classify
      // this.getDrugComposition(row.id)
    },
    /*根据药物id获取药物明细*/
    getDrugComposition(id) {
      info(id).then(response => {
        // console.log(response)
        response.data.dkbDrugIngredientList.forEach((item)=> {
          item.ingredientTypeDesc = item.substanceTypeDesc
          item.ingredientType = item.substanceType
        })
        this.drugCompositionList = response.data.dkbDrugIngredientList
      })
    },
    // 新增设置新的药物
    addSetDrug() {
      this.$router.push('/drug/edit/')
    },
    addSite(index, row) {
      // console.log(index, row)
      // console.log(this.resultNameOptions)
      const item = this.resultNameOptions.find(item => item.siteId === row.siteId)
      if (item) {
        this.msgError('给药部位不能重复选择，请重新添加！')
        return false
      } else {
        this.isChoiceType = false
        let drugComponentObj = {}
        drugComponentObj.siteName = row.siteName
        drugComponentObj.siteId = row.siteId
        drugComponentObj.isDefault = row.isDefault
        drugComponentObj.id = row.id
        drugComponentObj.preparationId = row.preparationId
        // drugComponentArr.push(drugComponentObj)
        this.resultNameOptions.push(drugComponentObj)
        // console.log(this.resultNameOptions)
        this.$nextTick(() => {
          this.$refs.siteForm.doLayout()
        })
      }
    },
    addRoute(index, row) {
      // console.log(index, row)
      // console.log(this.resultNameOptions02)
      const item = this.resultNameOptions02.find(item => item.routeId === row.routeId)
      if (item) {
        this.msgError('用药途径不能重复选择，请重新添加！')
        return false
      } else {
        this.isChoiceType = false
        let drugComponentObj = {}
        drugComponentObj.routeName = row.routeName
        drugComponentObj.routeId = row.routeId
        drugComponentObj.isDefault = row.isDefault
        drugComponentObj.id = row.id
        drugComponentObj.preparationId = row.preparationId
        this.resultNameOptions02.push(drugComponentObj)
        // console.log(this.resultNameOptions02)
        this.$nextTick(() => {
          this.$refs.routeForm.doLayout()
        })
      }
    },
    /*设置首选项单选按钮*/
    setPrimary(type) {
      let field = type === 'name' ? 'preparationAliasList' : 'dkbSubstanceDesc'
      let primaryField = type === 'name' ? 'namePrimary' : 'descPrimary'
      let preparation = this.preparation
      preparation[field].forEach(function(item, index) {
        if (item.isDefault === true) {
          preparation[primaryField] = index
          return
        }
      })
    },
    primaryChange(value, type, index) {
      // console.log(value, type, index)
      let field = type === 'name' ? 'preparationAliasList' : 'dkbSubstanceDesc'
      let primaryField = type === 'name' ? 'namePrimary' : 'descPrimary'
      this.preparation[primaryField] = index
      // console.log(this.preparation[primaryField])
      this.preparation[field].forEach(function(item, i) {
        if (i === index) {
          item.isDefault = true
        } else {
          item.isDefault = false
        }
      })
      this.$forceUpdate()
    },
    /*修改药物时，根据已选择的药物属性名称，渲染药物属性值下拉列表*/
    setSubstancePropValOption() {
      let that = this
      that.preparation.dkbSubstanceProps.forEach(function(item, index) {
        let options = that.substancePropsVal[item.name]
        if (options) {
          that.propsNameValSelectOpts[index] = options
        }

      })
    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
    deldrug(name) {
      // console.log(name)
      this.$confirm('是否删除名为"' + name + '"的药物?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.preparation.drugName = ''
        this.preparation.drugId = ''
        this.classify = ''
      })
    },
    // 复制药品名称
    copyDrugName(item) {
      // console.log(item)
      if (this.preparation.drugName === null || this.preparation.drugName === '' || this.preparation.drugName === undefined) {
        this.msgError('所属药物为空不能复制，请先添加所属药物！')
        return false
      } else {
        item.preparationAlias = this.preparation.drugName
      }
    },
    /** 来源按钮操作 */
    handleSource(row, index) {
      this.specIndex = index
      this.resourceTableVisible = true
      if (row.dkbReferenceInfoList) {
        this.resourceTableForm.list = row.dkbReferenceInfoList
      } else {
        this.resourceTableForm.list = []
      }
    },
    // 新建药品
    setDrugProd(row, index) {
      // console.log(row)
      this.$router.push('/drugProd/addByPpSpecId/' + row.specGroupKey)
    },
    /*添加一个来源*/
    addResource() {
      this.resourceTableForm.list.push({
        sourceTable: tableConst.DKB_PP_SPEC, // dkb_pp_spec
        sourceTableField: '',
        sourceTableId: this.editTableRow.preparationId,
        resourceTypeId: null,
        resourceId: null
      })
    },
    /* 保存引用资源*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {
          // console.log(this.resourceTableForm.list)
          this.tableData[this.specIndex].dkbReferenceInfoList = this.resourceTableForm.list
          // console.log(this.tableData)
          this.resourceTableVisible = false
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    resourceNameChange() {
      this.resourceDataOption = []
    },
    /*引用资源名称下拉列表搜索*/
    resourceNameQuery(query, resourceTypeId) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)

    },
    /** 转换剂型树形数据结构 */
    normalizerTreeNode(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }

      return {
        id: node.conceptId,
        label: node.conceptVal,
        children: node.children
      }
    },
    // 浓度正数，保留两位小数
    oninput(num) {
      var str = num
      var len1 = str.substr(0, 1)
      var len2 = str.substr(1, 1)
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1)
      }
      //第一位不能是.
      if (len1 == ".") {
        str = ""
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1)
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
        }
      }
      //正则替换
      str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
      str = str.replace(/\.\d\d\d$/,'') // 小数点后只能输两位
      return str
    },
    /* 名称类型-校验*/
    validateAliasType(rule, value, callback, index) {
      if (!isEmpty(value)) {
        let allName=this.preparation.preparationAliasList
        let englishNameSize=0
        let genericNameSize=0
        allName.forEach(function(item, i) {
          if(item.aliasType==conceptConst.PUB_NAME_TYPE_EN){
            englishNameSize++
          } else if(item.aliasType==conceptConst.PUB_NAME_TYPE_GENERIC){
            genericNameSize++
          }
        })
        if(englishNameSize>1){
          callback(new Error('英文名只能有一个'))
        }else if(genericNameSize>1){
          callback(new Error('通用名只能有一个'))
        }else{
          callback()
        }

      }else {
        callback()
      }

    },
    async editNext(){
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/preparationSpecification')
          return
        })
      }
      let nowIndex=-1
      let id=this.preparation.preparationId
      this.pageData.some((item,index)=>{
        if(item.preparationId==id){
          nowIndex=index
          return true
        }
      })
      if(nowIndex>-1 && nowIndex< this.pageData.length-1){
        id=this.pageData[nowIndex+1].preparationId
      }else{
        //查询下一页数据
        this.queryParams.pageNum++
        //向上取整
        let maxPage=Math.ceil(this.queryParams.total/this.queryParams.pageSize)
        if (this.queryParams.pageNum>maxPage){
          this.msgInfo('已经是最后一条记录了')
          this.queryParams.pageNum--
          return;
        }
        let response=await preparationList(this.queryParams)
        this.queryParams.total=response.data.total
        setCache("preparationQueryParams",this.queryParams)
        setCache("preparationPageData",response.data.rows)
        this.pageData= response.data.rows
        id=this.pageData[0].preparationId
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        this.$router.push('/preparationSpecification/edit/' + id)
      })
    },
    async editPrev(){
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/preparationSpecification')
          return
        })
      }
      let nowIndex=-1
      let id=this.preparation.preparationId
      this.pageData.some((item,index)=>{
        if(item.preparationId==id){
          nowIndex=index
          return true
        }
      })
      //console.log(id,this.pageData,nowIndex,"-----",this.queryParams,this.queryParams.pageNum)
      if(nowIndex>0 ){
        id=this.pageData[nowIndex-1].preparationId
      }else{
        //查询上一页数据
        this.queryParams.pageNum--
        if(this.queryParams.pageNum<1){
          this.msgInfo('已经是第一条记录了')
          this.queryParams.pageNum++
          return;
        }
        let response=await preparationList(this.queryParams)
        this.queryParams.total=response.data.total
        setCache("preparationQueryParams",this.queryParams)
        setCache("preparationPageData",response.data.rows)
        this.pageData= response.data.rows
        id=this.pageData[response.data.rows.length-1].preparationId
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        this.$router.push('/preparationSpecification/edit/' + id)
      })

    },
    /* 制剂名称-重复性校验*/
    validatePreparationAlias(rule, value, callback,index){
      if(!isEmpty(value)){
        // 调用接口校验是否有重复
        let obj = {}
        obj.fieldName = 'preparation_alias'
        obj.fieldValue = value
        obj.classify = this.classify
        obj.preparationId =this.preparation.preparationId
        checkFields(obj).then(response => {
          let item=response.data && response.data !== null ? response.data:undefined
          if(item){
            callback(new Error('制剂名称已存在,制剂id为['+item.preparationId+']'));
          }else{
            //页面表单校验
            let aliasList = this.preparation.preparationAliasList
            let key = Object.keys(index)[0]
            let nameIndex = key.replaceAll('preparationAliasList.', '').replaceAll('.preparationAlias', '')
            let nowName=aliasList[nameIndex]
            if (aliasList) {
              aliasList.forEach(function(j, i) {
                //名称完全一样算重复
                if (i != nameIndex && j.preparationAlias && j.preparationAlias.trim() === value.trim() ) {
                  callback(new Error('制剂名称已存在'))
                }
              })
            }

            callback()
          }
        })

      }else{
        callback();
      }
    },
    /*初始化药品成分编辑弹窗表单字段*/
    initSpecIngredientForm() {
      this.specIngredientForm = {
        /*当前编辑表单对应的表格行index*/
        index: undefined,
        /*物质id*/
        substanceId: undefined,
        /*物质名称*/
        substanceName: '',
        ingredientType: undefined,
        ingredientTypeDesc: '',
        quantityType: undefined,
        quantityTypeDesc: '',
        denominatorQty: undefined,
        denominatorQtyUnitId: undefined,
        denominatorQtyUnitDesc: '',
        substanceQtyMin: undefined,
        substanceQtyMax: undefined,
        substanceQtyUnitId: undefined,
        substanceQtyUnitDesc: '',
        substanceReferenceDesc: '',
        dkbPdSpecList: [],
        /*数量单位字典*/
        quantityUnitDict: [],
      }
    },
    /*新增/编辑药品成分*/
    editSpecIngredient(row, index) {
      this.initSpecIngredientForm()
      let form = this.$refs['addSpecIngredientForm']
      if (form) {
        form.clearValidate()
      }
      this.substanceList = []
      if (row != undefined) {
        //编辑成分
        Object.assign(this.specIngredientForm, row)
        this.specIngredientForm.index = index
        this.substanceList.push({ substanceId: row.substanceId, name: row.substanceName })
        console.log(row)
        this.isEditIngredient = true
        this.ppSpecIngredientList = row.ppSpecIngredientList
        this.singleSpecId = row.id
        this.specId = row.specId
        this.specIndex = index
      } else {
        //新增成分
        // 成分类型
        if (this.drugIngredientTypeDict && this.drugIngredientTypeDict.length > 0) {
          this.specIngredientForm.ingredientType = this.drugIngredientTypeDict[0].conceptId
          this.specIngredientForm.ingredientTypeDesc = this.drugIngredientTypeDict[0].conceptVal
        }
      }
      // this.addSpecIngredientDialogVisible = true
    },
    /*成分编辑弹窗-取消按钮*/
    cancelSpecIngredient() {
      this.addSpecIngredientDialogVisible = false
    },
    /*成分编辑弹窗-确定按钮*/
    confirmSpecIngredient() {

      this.$refs['addSpecIngredientForm'].validate(valid => {
        if (valid) {
          //this.msgSuccess('成分编辑表单校验完成')
          let form = this.specIngredientForm
          //处理字典项-物质名称
          this.substanceList.forEach(function(item) {
            if (item.substanceId == form.substanceId) {
              form.substanceName = item.name
              return
            }
          })

          //处理字典项-数量类型
          form.quantityTypeDesc = this.getConceptVal(this.quantityTypeDict, form.quantityType)
          //处理字典项-物质数量单位
          form.substanceQtyUnitDesc = this.getConceptVal(this.quantityUnitDict, form.substanceQtyUnitId)
          //处理字典项-分母数量单位
          form.denominatorQtyUnitDesc = this.getConceptVal(this.quantityUnitDict, form.denominatorQtyUnitId)
          //处理字典项-成分类型
          form.ingredientTypeDesc = this.getConceptVal(this.drugIngredientTypeDict, form.ingredientType)

          let index = this.specIngredientForm.index
          if (index != undefined) {
            //编辑成分
            Object.assign(this.dpSpecIngredientList[index], this.specIngredientForm)
          } else {
            //新增成分
            this.dpSpecIngredientList.push(this.specIngredientForm)
            this.specIngredientForm = {}
          }
          this.addSpecIngredientDialogVisible = false
        } else {
          this.msgError('请规范填写成分编辑')
        }
      })

    },
    /*根据conceptId从字典中获取概念值的描述*/
    getConceptVal(dict, conceptId) {
      let conceptVal
      dict.forEach(function(item) {
        if (item.conceptId === conceptId) {
          conceptVal = item.conceptVal
        }
      })
      return conceptVal
    },
    /*添加药品成分弹窗--物质名称检索*/
    async querySubstanceName(query) {
      if (!isEmpty(query)) {
        this.loading = true
        let res = await selectSubstanceNameList(query.trim())
        if (res.code === 200) {
          this.loading = false
          this.substanceList = res.data
        }
      } else {
        this.substanceList = []
      }
    },
    /* 物质数量单位--下拉搜索过滤*/
    substanceQtyUnitFilter(query) {
      if (query !== '') {
        this.quantityUnitDictOptions = this.quantityUnitDict.filter(item => {
          let flag = item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if (item.aliasList) {
            item.aliasList.some(aliasItem => {
              if ((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase()) > -1)
              ) {
                flag = true
                return true
              }
            })
          }
          return flag
        })

      } else {
        this.quantityUnitDictOptions = this.quantityUnitDict
      }
    },

  }
}
</script>


<style lang="scss" scoped>

/*分类标题*/
.title-bar {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}

/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}

.bottom-bar {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 60px;
  z-index: 200;
  font-size: 12px;
  line-height: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  padding-right: 260px;
}

///*药物编码div*/
//#substanceCodeDiv {
//  width: 100%;
//  /*height: 155px;*/
//  background: #EBF0FA;
//  border: 1px solid #BDD3FF;
//  border-radius: 4px;
//  padding: 10px 20px;
//  float:left;
//  position: relative;
//}
//
//#substanceCodeDiv p {
//  height: 14px;
//  line-height: 14px;
//  padding:5px 0px;
//}
//
//#substanceCodeDiv label {
//  display:block;
//  float:left;
//  width:80px;
//  text-align:right;
//  font-size: 14px;
//  font-family: PingFang SC;
//  font-weight: 600;
//  color: #666666;
//  margin-left: 20px;
//}
//
//#substanceCodeDiv span {
//  width:700px;
//  display:block;
//  float:left;
//  padding-left:5px;
//  font-size: 14px;
//  line-height:18px;
//  font-family: PingFang SC;
//  font-weight: 400;
//  color: #333333;
//}

/*推荐属性*/
#recommendProps button {
  min-width: 100px;
  color: #2B60F8
}

.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

.el-form-item {
  margin-bottom: 18px;
}

.jgs_pic {
  width: 180px;
  height: 180px;
  background: #EBF0FA;
  border: 1px solid #BDD3FF;
  border-radius: 4px;
  margin-top: 58px;
  position: absolute;
  right: 10px;
  top: -50px;
}

.belongDrug {
  width: 220px;
  height: 34px;
  background: #E6ECFE;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  display: block;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
}

.delIcon {
  position: absolute;
  right: -29px;
  top: 9px;
  color: #F52C2C;
}

.spanWrap p {
  margin: 0 !important;
}
</style>
