var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      staticStyle: {
        background: "#fff",
        height: "auto",
        overflow: "overlay",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: {
            padding: "20px",
            "flex-grow": "1",
            overflow: "hidden overlay",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "inside-menu-wrap", attrs: { span: 24 } },
                [
                  _c(
                    "ul",
                    { staticClass: "inside-menu" },
                    _vm._l(_vm.menulist, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: _vm.flag == index ? "liActive" : "",
                          on: {
                            click: function ($event) {
                              return _vm.chooseMenu(index)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: item.href } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "title-bar", attrs: { span: 24, id: "a01" } },
            [_vm._v("制剂基本信息")]
          ),
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "status-icon": "",
                model: _vm.preparation,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "drugComponentForm",
                          attrs: {
                            "status-icon": "",
                            model: _vm.drugComponent,
                            "label-width": "140px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.preparation.preparationId,
                                  expression: "preparation.preparationId",
                                },
                              ],
                              attrs: { label: "制剂ID:" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px", float: "left" },
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.preparation.preparationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.preparation,
                                      "preparationId",
                                      $$v
                                    )
                                  },
                                  expression: "preparation.preparationId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "所属药物",
                                prop: "drugComponentQueryStr",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入查询内容",
                                    trigger: "blur",
                                  },
                                  {
                                    min: 1,
                                    max: 30,
                                    message: "查询内容长度在 1 到 30 个字符",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _vm.preparation.drugId
                                ? _c("div", [
                                    _c("span", { staticClass: "belongDrug" }, [
                                      _vm._v(_vm._s(_vm.preparation.drugName)),
                                      _c("i", {
                                        staticClass: "el-icon-remove delIcon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deldrug(
                                              _vm.preparation.drugName
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "popper-class": "select_component",
                                    "remote-method": _vm.remoteMethod_drug,
                                    "reserve-keyword": "",
                                    placeholder: "请输入药物名称",
                                    clearable: "",
                                  },
                                  on: {
                                    clear: _vm.delDrug,
                                    change: function (val) {
                                      return _vm.changeRemote_drug(val)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.drugComponent.drugComponentQueryStr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.drugComponent,
                                        "drugComponentQueryStr",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "drugComponent.drugComponentQueryStr",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix table_Header" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            flex: "1",
                                          },
                                        },
                                        [_vm._v("药物Id")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "200px",
                                            flex: "2",
                                          },
                                        },
                                        [_vm._v("药物名称")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "200px",
                                            flex: "2",
                                          },
                                        },
                                        [_vm._v("别名")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            flex: "1",
                                          },
                                        },
                                        [_vm._v("操作")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.drugComponentTableData,
                                    function (item, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix spanWrap",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                    flex: "1",
                                                  },
                                                  attrs: { title: item.id },
                                                },
                                                [_vm._v(_vm._s(item.id))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                    flex: "2",
                                                  },
                                                  attrs: { title: item.name },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "300px",
                                                    flex: "2",
                                                  },
                                                },
                                                _vm._l(
                                                  item.dkbDrugAliasList,
                                                  function (aliasItem) {
                                                    return _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              aliasItem.alias !=
                                                              item.name,
                                                            expression:
                                                              "aliasItem.alias!=item.name",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            aliasItem.alias
                                                          ) + ";"
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                    flex: "1",
                                                    color: "#2B60F8",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addDrug(
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addClass",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                  },
                                  on: { click: _vm.addSetDrug },
                                },
                                [_vm._v("新增药物 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(
                _vm.preparation.preparationAliasList,
                function (item, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: "preparation-name-" + index,
                      attrs: {
                        label: index == 0 ? "制剂名称:" : "",
                        rules: [
                          {
                            required: true,
                            message: "请输入制剂名称",
                            trigger: "blur",
                          },
                          {
                            validator: _vm.validatePreparationAlias,
                            trigger: "blur",
                          },
                        ],
                        prop:
                          "preparationAliasList." + index + ".preparationAlias",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px", float: "left" },
                        attrs: {
                          placeholder: "请输入制剂名称",
                          maxlength: "300",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        model: {
                          value: item.preparationAlias,
                          callback: function ($$v) {
                            _vm.$set(item, "preparationAlias", $$v)
                          },
                          expression: "item.preparationAlias",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px", float: "left" },
                          attrs: { placeholder: "请选择语言" },
                          model: {
                            value: item.languageId,
                            callback: function ($$v) {
                              _vm.$set(item, "languageId", $$v)
                            },
                            expression: "item.languageId",
                          },
                        },
                        _vm._l(_vm.languageConcep, function (item) {
                          return _c("el-option", {
                            key: item.conceptId,
                            attrs: {
                              label: item.conceptVal,
                              value: item.conceptId,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            float: "left",
                            "margin-bottom": "0px",
                          },
                          attrs: {
                            rules: [
                              {
                                required: true,
                                message: "请选择名称类型",
                                trigger: "blur",
                              },
                              {
                                validator: _vm.validateAliasType,
                                trigger: "blur",
                              },
                            ],
                            prop:
                              "preparationAliasList." + index + ".aliasType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px", float: "left" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: item.aliasType,
                                callback: function ($$v) {
                                  _vm.$set(item, "aliasType", $$v)
                                },
                                expression: "item.aliasType",
                              },
                            },
                            _vm._l(_vm.aliasTypeConcept, function (item) {
                              return _c("el-option", {
                                key: item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("i", {
                        staticClass: "el-icon-remove",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.delSubstanceName(item)
                          },
                        },
                      }),
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            padding: "8px 8px 0 5px",
                          },
                          attrs: { label: index, border: "", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.primaryChange($event, "name", index)
                            },
                          },
                          model: {
                            value: _vm.preparation.namePrimary,
                            callback: function ($$v) {
                              _vm.$set(_vm.preparation, "namePrimary", $$v)
                            },
                            expression: "preparation.namePrimary",
                          },
                        },
                        [_vm._v(" 首选项 ")]
                      ),
                      _c("span"),
                      index == _vm.preparation.preparationAliasList.length - 1
                        ? _c("el-button", {
                            staticStyle: { "margin-left": "4px" },
                            attrs: {
                              icon: "el-icon-document-copy",
                              type: "primary",
                              title: "复制药物名称",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyDrugName(item)
                              },
                            },
                          })
                        : _vm._e(),
                      index == _vm.preparation.preparationAliasList.length - 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "addData",
                              staticStyle: {
                                width: "80px",
                                padding: "0px 0px 0px 16px",
                              },
                              attrs: { type: "primary", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSubstanceName("中文")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-circle-plus",
                                staticStyle: {
                                  color: "#7C9DF7",
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  float: "left",
                                },
                              }),
                              _c("svg-icon", {
                                staticStyle: { width: "24px", height: "16px" },
                                attrs: { "icon-class": "china" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      index == _vm.preparation.preparationAliasList.length - 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "addData",
                              staticStyle: {
                                width: "80px",
                                padding: "0px 0px 0px 16px",
                              },
                              attrs: { type: "primary", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSubstanceName("英语")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-circle-plus",
                                staticStyle: {
                                  color: "#7C9DF7",
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  float: "left",
                                },
                              }),
                              _c("svg-icon", {
                                staticStyle: { width: "24px", height: "16px" },
                                attrs: { "icon-class": "yingguoguoqi" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }
              ),
              _vm.classify !== 100403
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "剂型",
                        prop: "type",
                        rules: [
                          {
                            required: true,
                            message: "请选择剂型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _vm.selectFormTreeShow
                        ? _c("treeselect", {
                            staticStyle: { width: "300px", float: "left" },
                            attrs: {
                              options: _vm.selectFormTreeData,
                              normalizer: _vm.normalizerTreeNode,
                              "disable-branch-nodes": true,
                              "show-count": true,
                              placeholder: "选择剂型",
                              matchKeys: ["label", "spell", "wubi"],
                            },
                            on: { select: _vm.changeType },
                            model: {
                              value: _vm.preparation.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.preparation, "type", $$v)
                              },
                              expression: "preparation.type",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.classify === 100403
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "中药类别与规格：",
                        prop: "drugTypeSpecs",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px", float: "left" },
                          attrs: {
                            placeholder: "请选择中药类别与规格",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.remoteMethod_drugTypeSpecs,
                            "reserve-keyword": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.preparation.drugTypeSpecs,
                            callback: function ($$v) {
                              _vm.$set(_vm.preparation, "drugTypeSpecs", $$v)
                            },
                            expression: "preparation.drugTypeSpecs",
                          },
                        },
                        _vm._l(_vm.drugTypeSpecsList, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.classify === 100403
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "中药炮制方法：",
                        prop: "drugProcMethod",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px", float: "left" },
                          attrs: {
                            placeholder: "请选择中药炮制方法",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.remoteMethod_drugProc,
                            "reserve-keyword": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.preparation.drugProcMethod,
                            callback: function ($$v) {
                              _vm.$set(_vm.preparation, "drugProcMethod", $$v)
                            },
                            expression: "preparation.drugProcMethod",
                          },
                        },
                        _vm._l(_vm.drugProcMethod, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticStyle: { display: "none" }, attrs: { label: "浓度" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "300px", float: "left" },
                      attrs: { placeholder: "请输入浓度" },
                      nativeOn: {
                        keyup: function ($event) {
                          _vm.preparation.concentration = _vm.oninput(
                            _vm.preparation.concentration
                          )
                        },
                      },
                      model: {
                        value: _vm.preparation.concentration,
                        callback: function ($$v) {
                          _vm.$set(_vm.preparation, "concentration", $$v)
                        },
                        expression: "preparation.concentration",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            "font-style": "normal",
                            "margin-right": "10px",
                            color: "#333333",
                          },
                          attrs: { slot: "suffix" },
                          slot: "suffix",
                        },
                        [_vm._v("%")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "title-bar",
                      attrs: { span: 12, id: "a02" },
                    },
                    [
                      _vm._v("给药部位与用药途径 "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { type: "text" },
                          on: { click: _vm.clearSiteAndRoute },
                        },
                        [_vm._v("一键清空")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ppSiteBeanListForm",
                          attrs: {
                            "status-icon": "",
                            model: _vm.ppSiteBeanList,
                            "label-width": "140px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "给药部位列表",
                                prop: "drugComponentQueryStr",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "popper-class": "select_component",
                                    "remote-method": _vm.remoteMethod_site,
                                    "reserve-keyword": "",
                                    clearable: "",
                                    placeholder: "输入给药部位名称",
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.changeRemote_site(val)
                                    },
                                    focus: function (val) {
                                      return _vm.focusRemote_site(val)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.ppSiteBeanList.drugComponentQueryStr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSiteBeanList,
                                        "drugComponentQueryStr",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ppSiteBeanList.drugComponentQueryStr",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix table_Header" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "200px",
                                            flex: "2",
                                          },
                                        },
                                        [_vm._v("用药部位")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            flex: "1",
                                          },
                                        },
                                        [_vm._v("操作")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.siteTableData,
                                    function (item, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.siteId,
                                          attrs: {
                                            label: item.siteName,
                                            value: item.siteId,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix spanWrap",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                    flex: "2",
                                                  },
                                                  attrs: {
                                                    title: item.siteName,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.siteName))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                    flex: "1",
                                                    color: "#2B60F8",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addSite(
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ppRouteBeanListForm",
                          attrs: {
                            "status-icon": "",
                            model: _vm.ppRouteBeanList,
                            "label-width": "140px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "用药途径列表",
                                prop: "drugComponentQueryStr",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "popper-class": "select_component",
                                    "remote-method": _vm.remoteMethod_Route,
                                    "reserve-keyword": "",
                                    clearable: "",
                                    placeholder: "输入用药途径名称",
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.changeRemote_Route(val)
                                    },
                                    focus: function (val) {
                                      return _vm.focusRemote_Route(val)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.ppRouteBeanList.drugComponentQueryStr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppRouteBeanList,
                                        "drugComponentQueryStr",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ppRouteBeanList.drugComponentQueryStr",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix table_Header" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "200px",
                                            flex: "2",
                                          },
                                        },
                                        [_vm._v("用药途径")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            flex: "1",
                                          },
                                        },
                                        [_vm._v("操作")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.routeTableData,
                                    function (item, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.routeId,
                                          attrs: {
                                            label: item.routeName,
                                            value: item.routeId,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix spanWrap",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                    flex: "2",
                                                  },
                                                  attrs: {
                                                    title: item.routeName,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.routeName))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                    flex: "1",
                                                    color: "#2B60F8",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addRoute(
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { padding: "0px 0px 15px 15px" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { span: 10 },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "siteForm",
                          attrs: {
                            data: _vm.resultNameOptions,
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            height: 400,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { property: "siteName", label: "用药部位" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "首选项" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleSite(scope)
                                          },
                                        },
                                        model: {
                                          value: scope.row.isDefault,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "isDefault",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.isDefault",
                                        },
                                      },
                                      _vm._l(
                                        _vm.firstChoiceOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteSiteRow(
                                              scope.$index,
                                              _vm.resultNameOptions
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除 ")]
                                    ),
                                    scope.row.isDefault === true
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "#1e1e1e" },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                              disabled: "",
                                            },
                                          },
                                          [_vm._v("默认推荐值 ")]
                                        )
                                      : scope.row.isDefault === false
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setSiteRow(
                                                  scope.$index,
                                                  _vm.resultNameOptions
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("设为推荐值 ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "routeForm",
                          attrs: {
                            data: _vm.resultNameOptions02,
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            height: 400,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { property: "routeName", label: "用药途径" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "首选项" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleRoute(scope)
                                          },
                                        },
                                        model: {
                                          value: scope.row.isDefault,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "isDefault",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.isDefault",
                                        },
                                      },
                                      _vm._l(
                                        _vm.firstChoiceOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRouteRow(
                                              scope.$index,
                                              _vm.resultNameOptions02
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除 ")]
                                    ),
                                    scope.row.isDefault === true
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "#1e1e1e" },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                              disabled: "",
                                            },
                                          },
                                          [_vm._v("默认推荐值 ")]
                                        )
                                      : scope.row.isDefault === false
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setRouteRow(
                                                  scope.$index,
                                                  _vm.resultNameOptions02
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("设为推荐值 ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "title-bar",
                      attrs: { span: 12, id: "a03" },
                    },
                    [_vm._v("制剂规格列表")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "text-align": "right",
                            background: "#2B60F8",
                          },
                          attrs: { type: "primary" },
                          on: { click: _vm.addGg },
                        },
                        [_vm._v(" 添加规格 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-bottom": "20px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "tableData",
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            data: _vm.tableData,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "规格ID",
                              prop: "specId",
                              sortable: "",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.specId) +
                                            "-" +
                                            _vm._s(scope.row.id)
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      scope.row.drugCategoryList,
                                      function (item) {
                                        return _c(
                                          "span",
                                          { key: item.id },
                                          [
                                            item.icon
                                              ? _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": item.icon,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "showSpec",
                              sortable: "",
                              label: "规格",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "isDefault", label: "首选项" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.isDefault === true
                                      ? _c("span", [_vm._v("是")])
                                      : _c("span"),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "descList",
                              sortable: "",
                              label: "规格描述",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.descList,
                                    function (item) {
                                      return _c("div", { key: item.id }, [
                                        item.isDefault === true
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v(_vm._s(item.specDesc)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            key: "status",
                            attrs: {
                              label: "启用",
                              align: "center",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-switch", {
                                      staticClass: "switch",
                                      attrs: {
                                        "active-color": "#5DB730",
                                        "active-text": "启用",
                                        "inactive-text": "停用",
                                        "inactive-color": "#B5CCF5",
                                        "active-value": "0",
                                        "inactive-value": "1",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleStatusChange(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.status,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "status", $$v)
                                        },
                                        expression: "scope.row.status",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              "class-name": "small-padding fixed-width",
                              width: "300",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateSpec(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("修改 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copydateSpec(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("复制 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteSpec(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSource(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("来源 ")]
                                    ),
                                    scope.row.id
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setDrugProd(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("新增药品 ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editSpecIngredient(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("成分 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copySpec(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("创建同规格 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.drugCategoryEdit.refreshDrugCategoryTree(
                                              scope.row.id,
                                              5
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("分类维护 ")]
                                    ),
                                    scope.row.isDefault === true
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "#1e1e1e" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                              disabled: "",
                                            },
                                          },
                                          [_vm._v("默认首选规格 ")]
                                        )
                                      : scope.row.isDefault === false
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setSpecPrimary(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("设为首选规格 ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            {
              staticClass: "bottom-bar",
              staticStyle: {
                height: "80px",
                "padding-right": "0px",
                position: "fixed",
                right: "30px",
                bottom: "10px",
              },
              attrs: { span: 20 },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.preparation.preparationId,
                      expression: "preparation.preparationId",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editNext },
                },
                [_vm._v("下一个")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.preparation.preparationId,
                      expression: "preparation.preparationId",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editPrev },
                },
                [_vm._v("上一个")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.returnPage },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", display: "none" },
                  on: { click: _vm.resetForm },
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(1)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("保存并关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "药物检索",
            visible: _vm.drugComponent.drugComponentTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.drugComponent,
                "drugComponentTableVisible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.drugComponent.drugComponentTableData,
                height: 300,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "id", label: "药物ID", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { property: "name", label: "药物名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.choosedrugComponentData(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: { title: "引用来源管理", visible: _vm.resourceTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: { click: _vm.addResource },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableForm },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableForm.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "date", label: "引用资源类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择药物名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { property: "name", label: "引用资源名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: "resourceDataOption" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/images/no-booking.svg") },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveResource },
                    },
                    [_vm._v("保存 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "成分编辑",
            "append-to-body": "",
            width: "60%",
            visible: _vm.addSpecIngredientDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSpecIngredientDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "addSpecIngredientForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.specIngredientForm,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "成分物质:",
                                prop: "substanceId",
                                rules: _vm.specIngredientFormRule.substanceId,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "280px",
                                    float: "left",
                                  },
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                    placeholder: "请输入关键词搜索",
                                    "remote-method": _vm.querySubstanceName,
                                    loading: _vm.loading,
                                  },
                                  model: {
                                    value: _vm.specIngredientForm.substanceId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "substanceId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.substanceId",
                                  },
                                },
                                _vm._l(_vm.substanceList, function (item) {
                                  return _c("el-option", {
                                    key: "substanceList" + item.substanceId,
                                    attrs: {
                                      label: item.name,
                                      value: item.substanceId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "成分类型:",
                                prop: "ingredientType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    float: "left",
                                  },
                                  model: {
                                    value:
                                      _vm.specIngredientForm.ingredientType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "ingredientType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.ingredientType",
                                  },
                                },
                                _vm._l(
                                  _vm.drugIngredientTypeDict,
                                  function (item) {
                                    return _c("el-option", {
                                      key:
                                        "drugIngredientTypeDict" +
                                        item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质数量:",
                                prop: "substanceQtyMax",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  min: 0,
                                  controls: "",
                                  "controls-position": "right",
                                  precision: 4,
                                },
                                model: {
                                  value: _vm.specIngredientForm.substanceQtyMax,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specIngredientForm,
                                      "substanceQtyMax",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "specIngredientForm.substanceQtyMax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质数量单位:",
                                prop: "substanceQtyUnitId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    float: "left",
                                  },
                                  attrs: {
                                    filterable: "",
                                    "filter-method": _vm.substanceQtyUnitFilter,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.quantityUnitDictOptions =
                                        _vm.quantityUnitDict
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.specIngredientForm.substanceQtyUnitId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "substanceQtyUnitId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.substanceQtyUnitId",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "", value: "" },
                                  }),
                                  _vm._l(
                                    _vm.quantityUnitDictOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key:
                                          "quantityUnitDict" + item.conceptId,
                                        attrs: {
                                          label: item.conceptVal,
                                          value: item.conceptId,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质参考说明:",
                                prop: "substanceReferenceDesc",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "500px", float: "left" },
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入物质参考说明",
                                  maxlength: "200",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value:
                                    _vm.specIngredientForm
                                      .substanceReferenceDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specIngredientForm,
                                      "substanceReferenceDesc",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "specIngredientForm.substanceReferenceDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                background: "#2B60F8",
                                float: "right",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmSpecIngredient },
                            },
                            [_vm._v("确定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: { click: _vm.cancelSpecIngredient },
                            },
                            [_vm._v("取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isAddSF
        ? _c("AddSpecification", {
            attrs: {
              visible: _vm.isAddSF,
              preparationId: _vm.preparationId,
              specMode: _vm.specMode,
              specData: _vm.specData,
              isEdit: _vm.isEdit,
              specModeChoice: _vm.specModeChoice,
              specTableData: _vm.tableData,
              drugCompositionList: _vm.drugCompositionList,
            },
            on: { tableValue: _vm.tableValue, closeAddSF: _vm.closeAddSF },
          })
        : _vm._e(),
      _c("drug-category-edit", { ref: "drugCategoryEdit" }),
      _vm.isEditIngredient
        ? _c("edit-ingredient", {
            attrs: {
              EditDialogVisible: _vm.isEditIngredient,
              singleSpecId: _vm.singleSpecId,
              specId: _vm.specId,
              ingredientData: _vm.ppSpecIngredientList,
            },
            on: {
              closeEditIngredient: _vm.closeEditIngredient,
              getIngredientList: _vm.getIngredientList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }