<template>
  <el-dialog
    v-dialogDrag
    :title="'药品成分管理-规格ID[' + specId+'-'+ singleSpecId + ']'"
    :visible.sync="EditDialogVisible"
    :before-close="closeDpEditDialog"
    width="70%"
    :close-on-click-modal="false"
  >
    <el-row style="margin-bottom: 10px;">
      <el-col :span="12" class="title-bar" id="a04">药品成分(包含成分)</el-col>
      <el-col :span="12" style="text-align: right;">
        <el-button type="primary" style="text-align:right;background: #2B60F8" @click="editSpecIngredient">
          添加成分
        </el-button>
      </el-col>
    </el-row>
    <el-row style="padding: 0px 0px 15px 15px;">
      <el-col :span="24">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="dpSpecIngredientList"
          ref="rno"
        >
          <el-table-column property="id" label="成分ID" width="100" sortable></el-table-column>
          <el-table-column property="substanceName" label="物质" min-width="100" sortable></el-table-column>
          <el-table-column property="substanceQty" label="物质数量" width="100">
            <template slot-scope="scope">
              {{ scope.row.substanceQty }}
              {{ scope.row.substanceQtyUnitDesc }}
            </template>
          </el-table-column>
          <el-table-column property="ingredientTypeDesc" label="成分类型" width="100" sortable></el-table-column>
          <el-table-column property="substanceReferenceDesc" label="物质参考说明" min-width="100"></el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editSpecIngredient(scope.$index,scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="delSpecIngredient(scope.$index,scope.row)" type="text" size="small"
                         style="color: #FF3333;"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-col>
    </el-row>
    <el-footer>
      <el-button type="primary" style="float: right" @click="submitSpecIngredientForm">
        保存成分
      </el-button>
    </el-footer>
    <el-dialog v-dialogDrag title="成分编辑" append-to-body width="60%" :visible.sync="addSpecIngredientDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row style="padding: 20px;">
        <el-form ref="addSpecIngredientForm" status-icon :model="specIngredientForm" label-width="120px"
        >
          <el-row>

            <el-col :span="14">
              <el-form-item label="成分物质:" prop="substanceId"
                            :rules="specIngredientFormRule.substanceId"
              >
                <el-select
                  v-model="specIngredientForm.substanceId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词搜索"
                  :remote-method="querySubstanceName"
                  :loading="loading"
                  style="width: 280px;float: left"

                >
                  <el-option
                    v-for="item in substanceList"
                    :key="'substanceList'+item.substanceId"
                    :label="item.name"
                    :value="item.substanceId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="成分类型:" prop="ingredientType">
                <el-select v-model="specIngredientForm.ingredientType"
                           style="width: 150px;float: left"
                >
                  <el-option
                    v-for="item in drugIngredientTypeDict"
                    :key="'drugIngredientTypeDict'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>

            <el-col :span="14">
              <el-form-item label="物质数量:" prop="substanceQty">
                <el-input-number v-model="specIngredientForm.substanceQty" :min="0" controls
                                 controls-position="right" :precision="4" style="width: 150px;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="物质数量单位:" prop="substanceQtyUnitId">
                <el-select v-model="specIngredientForm.substanceQtyUnitId"
                           style="width: 150px;float: left"
                           filterable
                           :filter-method="substanceQtyUnitFilter"
                           @focus="quantityUnitDictOptions=quantityUnitDict"
                >
                  <el-option label="" value></el-option>
                  <el-option
                    v-for="item in quantityUnitDictOptions"
                    :key="'quantityUnitDict'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="物质参考说明:" prop="substanceReferenceDesc"
              >
                <el-input type="textarea" :rows="6" placeholder="请输入物质参考说明"
                          v-model="specIngredientForm.substanceReferenceDesc"
                          maxlength="200" clearable show-word-limit
                          style="width: 500px;float: left;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-button type="primary" size="small"
                         style="margin-left: 20px;background: #2B60F8;float:right;"
                         @click="confirmSpecIngredient"
              >确定
              </el-button>
              <el-button style="float: right;margin-right: 10px;"
                         size="small" @click="cancelSpecIngredient"
              >取消
              </el-button>
            </el-col>
          </el-row>


        </el-form>
      </el-row>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { selectSubstanceNameList } from '@/api/dkm/substance'
import {
  specIngredient,
  editSpecIngredient
} from '@/api/dkm/preparationSpecification'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { isEmpty } from '@/utils/common'
export default {
  name: "EditIngredient",
  props: {
    EditDialogVisible:{
      type: Boolean,
      default: false
    },
    singleSpecId: { // 行id
      type: Number,
      default: null
    },
    specId: {
      type: Number,
      default: null
    },
    ingredientData: {
      type: Array,
      default: []
    },
    isGetArrShow:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return{
      loading: false,
      ingredientTitle:'',
      isEdit:false,
      dpSpecIngredientList: [], // 成分list
      /*添加药品成分弹窗*/
      addSpecIngredientDialogVisible: false,
      /*添加/修改药品成分表单*/
      specIngredientForm: {},
      /*添加/修改药品成分表单校验规则*/
      specIngredientFormRule: {
        substanceId: [{ required: true, message: '请选择成分物质', trigger: 'blur' }],
        quantityType: [],
        denominatorQty: [],
        denominatorQtyUnitId: []
      },
      /*修改成分弹窗--名称下拉列表数据*/
      substanceList: [],
      /*药物成分类型字典*/
      drugIngredientTypeDict: [],
      quantityUnitDictOptions: [],
      /*药品成分*/
      dkbDpSpecIngredientList: [],
      isGetArr: false // 是否从上个步骤拿到的成分list
    }
  },
  created() {
    // this.isEdit = this.EditDialogVisible
    this.isGetArr = this.isGetArrShow
    this.init()
  },
  methods: {
    async specIngredientFn(val) {
      let res = await specIngredient(val)
      console.log(res)
      if (res.data&& res.data.length>=0) {
        this.dpSpecIngredientList = res.data
      }
    },
    closeDpEditDialog() {
      // this.isEdit = false
      this.$emit('closeEditIngredient',false)
    },
    async init() {
      if (this.isGetArrShow) {
        console.log(this.ingredientData)
        this.dpSpecIngredientList = this.ingredientData
        this.isGetArr = true
      }  else {
        console.log(this.singleSpecId)
        if (this.singleSpecId) {
          await this.specIngredientFn(this.singleSpecId)
        }
      }
      /*获取药物成分类型字典*/
      await getConcept(conceptConst.DKB_DRUG_INGREDIENT_TYPE, 0).then(result => {
        this.drugIngredientTypeDict = result
      })
      /*获取数量类型字典*/
      await getConcept(conceptConst.PUB_MATH_OPERATOR, 0).then(result => {
        this.quantityTypeDict = result
      })
      /*获取质量单位字典*/
      await getConcept(conceptConst.PUB_MASS_UNIT, 0).then(result => {
        this.quantityUnitDict = result
        this.quantityUnitDictOptions = result
      })
    },
    /*初始化药品成分编辑弹窗表单字段*/
    initSpecIngredientForm() {
      this.specIngredientForm = {
        /*当前编辑表单对应的表格行index*/
        index: undefined,
        /*物质id*/
        substanceId: undefined,
        /*物质名称*/
        substanceName: '',
        ingredientType: undefined,
        ingredientTypeDesc: '',
        quantityType: undefined,
        quantityTypeDesc: '',
        denominatorQty: undefined,
        denominatorQtyUnitId: undefined,
        denominatorQtyUnitDesc: '',
        // substanceQtyMin: undefined,
        // substanceQtyMax: undefined,
        substanceQty: undefined,
        substanceQtyUnitId: undefined,
        substanceQtyUnitDesc: '',
        substanceReferenceDesc: '',
        dkbPdSpecList: [],
        /*数量单位字典*/
        quantityUnitDict: [],
      }
    },
    /*新增/编辑药品成分*/
    editSpecIngredient(index, row) {
      this.initSpecIngredientForm()
      let form = this.$refs['addSpecIngredientForm']
      if (form) {
        form.clearValidate()
      }
      this.substanceList = []
      if (row != undefined) {
        //编辑成分
        Object.assign(this.specIngredientForm, row)
        this.specIngredientForm.index = index
        this.substanceList.push({ substanceId: row.substanceId, name: row.substanceName})
      } else {
        //新增成分
        // 成分类型
        if (this.drugIngredientTypeDict && this.drugIngredientTypeDict.length > 0) {
          this.specIngredientForm.ingredientType = this.drugIngredientTypeDict[0].conceptId
          this.specIngredientForm.ingredientTypeDesc = this.drugIngredientTypeDict[0].conceptVal
        }
      }
      this.addSpecIngredientDialogVisible = true
    },
    /* 删除药品成分*/
    delSpecIngredient(index, row) {
      let specIngredientList = this.dpSpecIngredientList
      if (specIngredientList.length == 1) {
        this.$message('药品成分至少保留一个！')
      } else {
        specIngredientList.splice(index, 1)
      }
    },
    /*成分编辑弹窗-取消按钮*/
    cancelSpecIngredient() {
      this.addSpecIngredientDialogVisible = false
    },
    /*成分编辑弹窗-确定按钮*/
    confirmSpecIngredient() {

      this.$refs['addSpecIngredientForm'].validate(valid => {
        if (valid) {
          //this.msgSuccess('成分编辑表单校验完成')
          let form = this.specIngredientForm
          //处理字典项-物质名称
          this.substanceList.forEach(function(item) {
            if (item.substanceId == form.substanceId) {
              form.substanceName = item.name
              return
            }
          })

          //处理字典项-数量类型
          form.quantityTypeDesc = this.getConceptVal(this.quantityTypeDict, form.quantityType)
          //处理字典项-物质数量单位
          form.substanceQtyUnitDesc = this.getConceptVal(this.quantityUnitDict, form.substanceQtyUnitId)
          //处理字典项-分母数量单位
          form.denominatorQtyUnitDesc = this.getConceptVal(this.quantityUnitDict, form.denominatorQtyUnitId)
          //处理字典项-成分类型
          form.ingredientTypeDesc = this.getConceptVal(this.drugIngredientTypeDict, form.ingredientType)

          let index = this.specIngredientForm.index
          if (index != undefined) {
            //编辑成分
            Object.assign(this.dpSpecIngredientList[index], this.specIngredientForm)
          } else {
            //新增成分
            this.dpSpecIngredientList.push(this.specIngredientForm)
            this.specIngredientForm = {}
          }
          this.addSpecIngredientDialogVisible = false
        } else {
          this.msgError('请规范填写成分编辑')
        }
      })

    },
    /*根据conceptId从字典中获取概念值的描述*/
    getConceptVal(dict, conceptId) {
      let conceptVal
      dict.forEach(function(item) {
        if (item.conceptId === conceptId) {
          conceptVal = item.conceptVal
        }
      })
      return conceptVal
    },
    /*添加药品成分弹窗--物质名称检索*/
    async querySubstanceName(query) {
      if (!isEmpty(query)) {
        this.loading = true
        let res = await selectSubstanceNameList(query.trim())
        if (res.code === 200) {
          this.loading = false
          this.substanceList = res.data
        }
      } else {
        this.substanceList = []
      }
    },
    /* 物质数量单位--下拉搜索过滤*/
    substanceQtyUnitFilter(query) {
      if (query !== '') {
        this.quantityUnitDictOptions = this.quantityUnitDict.filter(item => {
          let flag = item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if (item.aliasList) {
            item.aliasList.some(aliasItem => {
              if ((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase()) > -1)
              ) {
                flag = true
                return true
              }
            })
          }
          return flag
        })

      } else {
        this.quantityUnitDictOptions = this.quantityUnitDict
      }
    },
    /*保存药品成分*/
    submitSpecIngredientForm() {
      // 是否从上个步骤拿到的成分list
      if (this.isGetArr) {
        this.$emit('getIngredientList',this.dpSpecIngredientList)
      } else {
        if (this.editFlag == false) {
          this.msgInfo('数据没有改变，无需保存')
          return
        }
        let formData = {}
        formData.specId = this.singleSpecId
        formData.ppSpecIngredientList = this.dpSpecIngredientList
        editSpecIngredient(formData).then(response => {
          this.msgSuccess(response.msg)
          this.$emit('editFinish')
          // this.$emit('closeEditIngredient',false)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
