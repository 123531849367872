<template>
  <el-dialog
    v-dialogDrag
    :visible.sync="visible"
    :before-close="closeAddSF"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    title="添加规格"
    class="addSpec"
    center
    width="1010px">
<!--    class="vertical-middle-dialog"-->
    <el-form ref="ppSpecBeanListref" :model="ppSpecBeanList" :rules="preparationRules" label-width="125px">
      <el-form-item label="规格模式：" prop="specMode" :rules="[ { validator: validateSpecMode}]">
        <el-radio-group v-model="ppSpecBeanList.specMode" class="specificationMode" @change="specModeChange">
          <el-row :gutter="22">
            <el-col :span="12" style="padding:12px 0px;"><el-radio label="1" :disabled="specModeChoice">质量（w）、体积（v）、国际单位（IU）、复方、草药</el-radio></el-col>
            <el-col :span="12" style="padding:12px 0px;"><el-radio label="9">高级模式</el-radio></el-col>
          </el-row>
          <el-row :gutter="22">
            <el-col :span="24" style="padding:12px 0px;"><el-radio label="2">质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）</el-radio></el-col>
          </el-row>
          <el-row :gutter="22">
            <el-col :span="24" style="padding:12px 0px;"><el-radio label="3">尺寸（长度×宽度）</el-radio></el-col>
          </el-row>
<!--          <el-row :gutter="24">-->
<!--            <el-col :span="5"><el-radio label="1">质量（w）</el-radio></el-col>-->
<!--            <el-col :span="5"><el-radio label="2">体积（v）</el-radio></el-col>-->
<!--            <el-col :span="5"><el-radio label="3">国际单位（IU）</el-radio></el-col>-->
<!--            <el-col :span="5"><el-radio label="4">复方</el-radio></el-col>-->
<!--            <el-col :span="4"><el-radio label="5">高级模式</el-radio></el-col>-->
<!--          </el-row>-->
<!--          <el-row :gutter="24">-->
<!--            <el-col :span="5"><el-radio label="6">质量/体积（w/v）</el-radio></el-col>-->
<!--            <el-col :span="5"><el-radio label="7">体积/体积（v/v）</el-radio></el-col>-->
<!--            <el-col :span="5"><el-radio label="8">国际单位/体积（IU/v）</el-radio></el-col>-->
<!--            <el-col :span="5"><el-radio label="10">质量/质量（w/w）</el-radio></el-col>-->
<!--            <el-col :span="4"><el-radio label="9">草药</el-radio></el-col>-->
<!--          </el-row>-->
        </el-radio-group>
      </el-form-item>
      <el-row
        v-if="ppSpecBeanList.specMode === '9'"
        :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="分子类型："
            prop="numeratorType"
            :rules="[{ required: true, message: '请输入分子类型', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.numeratorType" placeholder="请选择分子类型">
              <el-option
                v-for="item in quantityTypeDict"
                :key="'quantityTypeDict'+item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.numeratorType === 101807">
          <el-form-item label="分子数量(下限)：" prop="minNumeratorConstituent">
            <el-input v-model="ppSpecBeanList.minNumeratorConstituent" @keyup.native="ppSpecBeanList.minNumeratorConstituent = oninput(ppSpecBeanList.minNumeratorConstituent)" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="分子数量(上限)：" prop="maxNumeratorConstituent">
            <el-input v-model="ppSpecBeanList.maxNumeratorConstituent" @keyup.native="ppSpecBeanList.maxNumeratorConstituent = oninput(ppSpecBeanList.maxNumeratorConstituent)" placeholder="请输入内容"/>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="分子数量单位："
            prop="numeratorUnit"
            :rules="[{ required: true, message: '请输入分子数量单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.numeratorUnit" placeholder="请选择分子数量单位"
                       filterable
                       :filter-method="unitFilter_fz"
                       @focus="preparationUnitList_fz=preparationUnitDict">
              <el-option
                v-for="item in preparationUnitList_fz"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="ppSpecBeanList.specMode === '9'"
        :gutter="20">
        <el-col :span="6">
          <el-form-item label="分母数量：" prop="denominatorConstituent">
            <el-input v-model="ppSpecBeanList.denominatorConstituent" placeholder="请输入内容" @keyup.native="ppSpecBeanList.denominatorConstituent = oninput(ppSpecBeanList.denominatorConstituent)"/>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="分母数量单位："
            prop="denominatorUnit"
            :rules="[{ required: true, message: '请输入分母数量单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.denominatorUnit" placeholder="请选择分母数量单位"
                       filterable
                       :filter-method="unitFilter_fm"
                       @focus="preparationUnitList_fm=preparationUnitDict">
              <el-option
                v-for="item in preparationUnitList_fm"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="换算系数：" prop="convertFactor">
            <el-input v-model="ppSpecBeanList.convertFactor" placeholder="请输入内容" @keyup.native="ppSpecBeanList.convertFactor = oninput(ppSpecBeanList.convertFactor)"/>
          </el-form-item>
        </el-col>
<!--        规格单位暂时移除，放药品层级里面维护了-->
        <el-col :span="6" v-if="false">
          <el-form-item
            label="规格单位："
            label-width="95px"
            prop="specUnit"
            :rules="[{ required: true, message: '请输入规格单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.specUnit" placeholder="请选择规格单位"
             filterable
             :filter-method="specUnitFilter"
             @focus="specUnitList=specUnitDict"
            >
              <el-option
                v-for="item in specUnitList"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="ppSpecBeanList.specMode === '9'"
        :gutter="20">
<!--        <el-col :span="6">-->
<!--          <el-form-item-->
<!--            label="浓度类型:"-->
<!--            prop="concentrationType"-->
<!--            :rules="[{ required: true, message: '请输入浓度类型', trigger: 'blur'}]">-->
<!--            <el-select v-model="ppSpecBeanList.concentrationType" placeholder="请选择浓度类型">-->
<!--              <el-option-->
<!--                v-for="item in preparationUnitList"-->
<!--                :key="item.code"-->
<!--                :label="item.name"-->
<!--                :value="item.code"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="6" v-if="ppSpecBeanList.numeratorType === 101807">
          <el-form-item label="浓度(下限)：" prop="minConcentration">
            <el-input v-model="ppSpecBeanList.minConcentration" @keyup.native="ppSpecBeanList.minConcentration = oninput(ppSpecBeanList.minConcentration)" placeholder="请输入内容">
              <i slot="suffix" style="font-style:normal;margin-right: 10px;color: #333333">%</i>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="浓度(上限)：" prop="maxConcentration">
            <el-input v-model="ppSpecBeanList.maxConcentration" @keyup.native="ppSpecBeanList.maxConcentration = oninput(ppSpecBeanList.maxConcentration)" placeholder="请输入内容">
              <i slot="suffix" style="font-style:normal;margin-right: 10px;color: #333333">%</i>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="ppSpecBeanList.specMode !== '9'"
        :gutter="20">
        <el-col :span="6" v-if="ppSpecBeanList.specMode !== '3'">
          <el-form-item label="剂量：" prop="maxNumeratorConstituent">
            <el-input v-model="ppSpecBeanList.maxNumeratorConstituent" placeholder="请输入内容" @keyup.native="ppSpecBeanList.maxNumeratorConstituent = oninput(ppSpecBeanList.maxNumeratorConstituent)"/>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode !== '3'">
          <el-form-item
            label="剂量单位："
            prop="numeratorUnit"
            :rules="[{ required: true, message: '请输入剂量单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.numeratorUnit" placeholder="请选择剂量单位"
                       filterable
                       :filter-method="unitFilter_jl"
                       @focus="preparationUnitList_jl=preparationUnitDict">
              <el-option
                v-for="item in preparationUnitList_jl"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode === '3'">
          <el-form-item label="长度：" prop="specLength">
            <el-input v-model="ppSpecBeanList.specLength" placeholder="请输入内容" @keyup.native="ppSpecBeanList.specLength = oninput(ppSpecBeanList.specLength)"/>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode === '3'">
          <el-form-item
            label="长度单位："
            prop="specLengthUnit"
            :rules="[{ required: true, message: '请输入长度单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.specLengthUnit" placeholder="请选择长度单位"
                       filterable
                       :filter-method="unitFilter_jl"
                       @focus="preparationUnitList_jl=preparationUnitDict">
              <el-option
                v-for="item in lengthUnit"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode === '3'">
          <el-form-item label="宽度：" prop="specWidth">
            <el-input v-model="ppSpecBeanList.specWidth" placeholder="请输入内容" @keyup.native="ppSpecBeanList.specWidth = oninput(ppSpecBeanList.specWidth)"/>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode === '3'">
          <el-form-item
            label="宽度单位："
            prop="specWidthUnit"
            :rules="[{ required: true, message: '请输入宽度单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.specWidthUnit" placeholder="请选择宽度单位"
                       filterable
                       :filter-method="unitFilter_jl"
                       @focus="preparationUnitList_jl=preparationUnitDict">
              <el-option
                v-for="item in lengthUnit"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode === '2'">
          <el-form-item label="分母数量：" prop="denominatorConstituent">
            <el-input v-model="ppSpecBeanList.denominatorConstituent" placeholder="请输入内容" @keyup.native="ppSpecBeanList.denominatorConstituent = oninput(ppSpecBeanList.denominatorConstituent)"/>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode === '2'">
          <el-form-item
            label="分母数量单位："
            prop="denominatorUnit"
            :rules="[{ required: true, message: '请输入分母数量单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.denominatorUnit" placeholder="请选择分母数量"
                       filterable
                       :filter-method="unitFilter_fm_ordinary"
                       @focus="preparationUnitList_fm_ordinary=preparationUnitDict">
              <el-option
                v-for="item in preparationUnitList_fm_ordinary"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
<!--        <el-col :span="6" v-if="ppSpecBeanList.specMode == '10'">-->
<!--          <el-form-item label="分母数量：" prop="denominatorConstituent">-->
<!--            <el-input v-model="ppSpecBeanList.denominatorConstituent" placeholder="请输入内容" />-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :span="6" v-if="ppSpecBeanList.specMode == '10'">-->
<!--          <el-form-item-->
<!--            label="分母数量单位:"-->
<!--            prop="denominatorUnit"-->
<!--            :rules="[{ required: true, message: '请输入分母数量单位', trigger: 'blur'}]">-->
<!--            <el-select v-model="ppSpecBeanList.denominatorUnit" placeholder="请选择分母数量单位"-->
<!--                       filterable-->
<!--                       :filter-method="unitFilter"-->
<!--                       @focus="preparationUnitList=preparationUnitDict">-->
<!--              <el-option-->
<!--                v-for="item in preparationUnitList"-->
<!--                :key="item.conceptId"-->
<!--                :label="item.conceptVal"-->
<!--                :value="item.conceptId"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <!--        规格单位暂时移除，放药品层级里面维护了-->
        <el-col :span="6" v-if="false">
          <el-form-item
            label="规格单位："
            prop="specUnit"
            :rules="[{ required: true, message: '请输入规格单位', trigger: 'blur'}]">
            <el-select v-model="ppSpecBeanList.specUnit" placeholder="请选择规格单位"
             filterable
             :filter-method="specUnitFilter"
             @focus="specUnitList=specUnitDict"
            >
              <el-option
                v-for="item in specUnitList"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="ppSpecBeanList.specMode === '2'">
          <el-form-item label="浓度：" prop="mage">
            <el-input v-model="ppSpecBeanList.maxConcentration"
                      :disabled="isConcentrationShow"
                      @keyup.native="ppSpecBeanList.maxConcentration = oninput(ppSpecBeanList.maxConcentration)"
                      placeholder="请输入内容">
              <i slot="suffix" style="font-style:normal;margin-right: 10px;color: #333333">%</i>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        v-for="(item, index) in ppSpecBeanList.descList"
        :label="index==0?'规格描述：':''"
        :key="'spec-name-'+index"
        :rules="[{ required: true, message: '请输入规格描述', trigger: 'blur'}]"

        :prop="'descList.' + index + '.specDesc'"
        style="margin-bottom: 18px;"
      >
        <el-input type="textarea" placeholder="请输入规格描述" v-model="item.specDesc" style="width: 600px;float: left"
                  maxlength="500" clearable show-word-limit
        />
        <i class="el-icon-remove" @click.prevent="delSubstanceName(item)"></i>
        <el-radio v-model="ppSpecBeanList.namePrimary"
                  :label="index"
                  border
                  style="margin-right:10px;"
                  @change="(val) => primaryChange(val,'name',index)"
        >
          设置为首选项
          <!--                  @change="primaryChange($event,'name',index)"-->
        </el-radio>
        <el-button type="text" @click="splitRuleDescription(item.specDesc)">拆分</el-button>
      </el-form-item>

      <el-form-item style="margin-bottom: 10px;">
        <el-button type="primary" plain class="addData" style="width: 600px;"
                   @click="addSubstanceName"
        >
          <i class="el-icon-circle-plus el-icon--left" style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"></i>
          添加
        </el-button>
      </el-form-item>
<!--      <el-row :gutter="20">-->
<!--        <el-form-item label="首选规格：">-->
<!--          <el-checkbox v-model="ppSpecBeanList.isDefault">设置为首选规格</el-checkbox>-->
<!--&lt;!&ndash;          <el-checkbox-group v-model="ppSpecBeanList.isDefault">&ndash;&gt;-->
<!--&lt;!&ndash;            <el-checkbox label="设置为首选规格" name="isDefault"></el-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-checkbox-group>&ndash;&gt;-->
<!--        </el-form-item>-->
<!--      </el-row>-->
      <el-row
        v-if="ppSpecBeanList.specMode !== '9'"
        :gutter="20">
        <el-form-item label="显示规格：">
<!--          25mg/瓶-->
          <span v-if="ppSpecBeanList.specMode === '1'"
                class="spanFontSize">
            {{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}
          </span>
          <span v-if="ppSpecBeanList.specMode === '2'"
                class="spanFontSize">
            {{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
          <span v-if="ppSpecBeanList.specMode === '3'"
                class="spanFontSize">
            {{ppSpecBeanList.specLength}}{{ppSpecBeanList.specLengthUnit | lengthUnitChoice }}×{{ppSpecBeanList.specWidth}}{{ppSpecBeanList.specWidthUnit | lengthUnitChoice}}
          </span>
          <span v-if="ppSpecBeanList.specMode === '9'"
                class="spanFontSize">
            {{ppSpecBeanList.minNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.minConcentration !== null">({{ ppSpecBeanList.minConcentration }}%)</span>～{{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
        </el-form-item>
      </el-row>
      <el-row
        v-if="ppSpecBeanList.specMode === '9'"
        :gutter="20">
        <el-form-item label="显示规格：" label-width="125px" style="margin-bottom:10px;">
          <span v-if="ppSpecBeanList.numeratorType === 101801" class="spanFontSize">
           <!--    =    -->
            {{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
          <span v-if="ppSpecBeanList.numeratorType === 101803" class="spanFontSize">
           <!--    >    -->
            >{{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
          <span v-if="ppSpecBeanList.numeratorType === 101804" class="spanFontSize">
           <!--    >=    -->
            >={{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
          <span v-if="ppSpecBeanList.numeratorType === 101805" class="spanFontSize">
           <!--    <    -->
            <{{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
          <span v-if="ppSpecBeanList.numeratorType === 101806" class="spanFontSize">
           <!--    <=    -->
            <={{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
          <span v-if="ppSpecBeanList.numeratorType === 101807" class="spanFontSize">
           <!--    ~    -->
            {{ppSpecBeanList.minNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.minConcentration !== null">({{ ppSpecBeanList.minConcentration }}%)</span>～{{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}<span v-if="ppSpecBeanList.maxConcentration !== null">({{ ppSpecBeanList.maxConcentration }}%)</span>
          </span>
        </el-form-item>
      </el-row>
    </el-form>
    <el-footer style="text-align: right">
      <el-button @click="closeAddSF">取消</el-button>
      <el-button @click="resetForm">重置</el-button>
      <el-button type="primary" @click="saveSpec('ppSpecBeanListref')">保存</el-button>
    </el-footer>
    <el-dialog title="选择制剂规格" width="60%" append-to-body :visible.sync="specSelectDialogVisible"
               :close-on-click-modal="false">
      <el-row style="height:400px;overflow: auto">
        <p style="font-size: 15px;font-weight: bold;">根据当前已输入的规格描述，匹配到多个制剂规格，请选择一个制剂规格</p>
        <el-divider></el-divider>
        <ul style="font-size: 15px;">
          <li  v-for="(item, index) in specList">
            <span>{{item.numeratorVal}}{{item.numeratorUnitDesc}}</span><span v-if="item.denominatorVal">:{{item.denominatorVal}}{{item.denominatorUnitDesc}}</span>
            <el-button type="text" @click="selectSpec(item)">选择</el-button>
          </li>
        </ul>
      </el-row>

    </el-dialog>
  </el-dialog>
</template>

<script>
import { dict, modifySpec, addSpec,getConceptsByDomainCode, getConcentration, checkExistSameSpec, analyseSpec, specModeUnit } from '@/api/dkm/preparationSpecification'
import conceptConst from '@/const/conceptConst'
import { getConcept } from '@/utils/concept'
import { DEEP_CLONE } from '@/utils/constants'
import {
  PPSPECBEANLIST_DATA
} from '@/utils/addSpecification'
import { isEmpty } from '@/utils/common'
let that
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    specModeChoice: { // 第一个是否可选
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    specMode: {
      type: String
    },
    specData: {
      type: Object
    },
    specTableData: {
      type: Array
    },
    preparationId: {
      type: String
    },
    specGroupKey: {
      type: String
    },
    drugCompositionList: {
      type: Array
    }
  },
  filters: {
    // 剂量单位
    unitChoice(val) {
      // console.log(val)
      let unitName = ''
      // that.getConceptsByDomainCodeFn()
      // console.log(that.preparationUnitList)
      that.preparationUnitList.forEach((item) => {
        if (item.conceptId === val) {
          // console.log(item.conceptVal)
          unitName = item.conceptVal
        }
      })
      return unitName
    },
    lengthUnitChoice(val) {
      // console.log(val)
      let unitName = ''
      // that.getConceptsByDomainCodeFn()
      // console.log(that.preparationUnitList)
      that.lengthUnit.forEach((item) => {
        if (item.code === val) {
          // console.log(item.conceptVal)
          unitName = item.name
        }
      })
      return unitName
    },
    specChoice(val) {
      // console.log(val)
      let specName = ''
      // that.getConceptsByDomainCodeFn()
      // console.log(that.specUnitList)
      that.specUnitList.forEach((item) => {
        if (item.conceptId === val) {
          // console.log(item.conceptVal)
          specName = item.conceptVal
        }
      })
      return specName
    }
  },
  data () {
    // 分子下限判断
    var valiminNumeratorConstituent = (rule, value, callback) => {
      // console.log(rule, value, callback)
      let _this = this
      let re = /^[0-9]+.?[0-9]*$/ // 判断字符串是否为数字
      if (this.ppSpecBeanList.minNumeratorConstituent === '') {
        callback(new Error('分子下限不能为空'))
      } else if (!re.test(_this.ppSpecBeanList.minNumeratorConstituent)) {
        callback(new Error('分子下限为大于等于0的数字'))
      } else if (this.ppSpecBeanList.maxNumeratorConstituent) {
        this.$refs.ppSpecBeanListref.validateField('maxNumeratorConstituent');
      } else {
        callback()
      }
    }
    // 分母下限判断
    var valimaxNumeratorConstituent = (rule, value, callback) => {
      // console.log(rule, value, callback)
      let _this = this
      let re = /^[0-9]+.?[0-9]*$/ // 判断字符串是否为数字
      if (this.ppSpecBeanList.maxNumeratorConstituent === '') {
        callback(new Error('分子上限不能为空'))
      } else if (!re.test(_this.ppSpecBeanList.maxNumeratorConstituent)) {
        callback(new Error('分子上限为大于等于0的数字'))
      } else if (this.ppSpecBeanList.minNumeratorConstituent > this.ppSpecBeanList.maxNumeratorConstituent) {
        callback(new Error('分子上限不能小于分子下限'))
      } else {
        callback()
      }
    }
    // 浓度下限判断
    var valiminConcentration = (rule, value, callback) => {
      // console.log(rule, value, callback)
      let _this = this
      let re = /^[0-9]+.?[0-9]*$/ // 判断字符串是否为数字
      if (this.ppSpecBeanList.minConcentration === '') {
        callback(new Error('浓度下限不能为空'))
      } else if (!re.test(_this.ppSpecBeanList.minConcentration)) {
        callback(new Error('浓度下限为大于等于0的数字'))
      } else if (this.ppSpecBeanList.maxConcentration) {
        this.$refs.ppSpecBeanListref.validateField('maxConcentration');
      } else {
        callback()
      }
    }
    // 浓度上限判断
    var valimaxConcentration = (rule, value, callback) => {
      // console.log(rule, value, callback)
      let _this = this
      let re = /^[0-9]+.?[0-9]*$/ // 判断字符串是否为数字
      if (this.ppSpecBeanList.maxConcentration === '') {
        callback(new Error('浓度上限不能为空'))
      } else if (!re.test(_this.ppSpecBeanList.maxConcentration)) {
        callback(new Error('浓度上限为大于等于0的数字'))
      } else if (this.ppSpecBeanList.minConcentration > this.ppSpecBeanList.maxConcentration) {
        callback(new Error('浓度上限不能小于浓度下限'))
      } else {
        callback()
      }
    }
    return {
      conceptConst,
      isSameSpec: false, // 检查制剂规格是否存在相同的项
      specOriginalData: DEEP_CLONE(this.specTableData),
      ppSpecBeanList: DEEP_CLONE(PPSPECBEANLIST_DATA),
      drugCompositionListData: DEEP_CLONE(this.drugCompositionList),
      // 验证规则
      preparationRules: {
        // maternityNum: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        // mname: [{ required: true, message: '请输入母亲姓名', trigger: 'blur' }],
        // registerNum: [{ required: true, message: '编号为空', trigger: 'blur' }],
        // name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        // birthday: [{ required: true, message: '请输入生日', trigger: 'blur' }],
        // mphone: [{ pattern: /^[1][3,4,5,6,7,8][0-9]{9}$/, message: '格式错误', trigger: 'blur' }],
        // fphone: [{ pattern: /^[1][3,4,5,6,7,8][0-9]{9}$/, message: '格式错误', trigger: 'blur' }],
        minNumeratorConstituent: [
          { required: true, message: '请选择分子下限', trigger: 'blur' }
        ],
        maxNumeratorConstituent: [
          { required: true, message: '请选择分子上限', trigger: 'blur' }
        ],
        minConcentration: [
          { required: true, message: '请选择浓度下限', trigger: 'blur' }
        ],
        maxConcentration: [
          { required: true, message: '请选择浓度上限', trigger: 'blur' }
        ],
        specLength: [
          { required: true, message: '请输入长度', trigger: 'blur' }
        ],
        specWidth: [
          { required: true, message: '请输入宽度', trigger: 'blur' }
        ],
        specLengthUnit: [
          { required: true, message: '请选择长度单位', trigger: 'change' }
        ],
        specWidthUnit: [
          { required: true, message: '请选择宽度单位', trigger: 'change' }
        ],
        // minNumeratorConstituent: [ // 分子下限
        //   { required: true, validator: valiminNumeratorConstituent, trigger: 'blur' }
        // ],
        // maxNumeratorConstituent: [ // 分子上限
        //   { required: true, validator: valimaxNumeratorConstituent, trigger: 'blur' }
        // ],
        // minConcentration: [ // 浓度下限
        //   { required: true, validator: valiminConcentration, trigger: 'blur' }
        // ],
        // maxConcentration: [ // 浓度上限
        //   { required: true, validator: valimaxConcentration, trigger: 'blur' }
        // ]
      },
      preparationUnitDict: [], // 剂量单位字典
      preparationUnitList: [], // 剂量单位
      preparationUnitList_fz: [], // fz
      preparationUnitList_fm: [], // fm
      preparationUnitList_fm_ordinary: [], // fm高级
      preparationUnitList_jl: [], // jl
      lengthUnit: [], // 长度单位
      specUnitDict: [], // 规格单位字典
      specUnitList: [], // 规格单位
      quantityTypeDict: [], // 分子质量单位
      isConcentrationShow: false, // 浓度是否显示  specMode === '2'
      // preparationQualityUnitList: [], // 制剂单位-剂量
      // preparationVolumeUnitList: [] // 制剂单位-体积
      specSelectDialogVisible: false, // 拆分制剂规格选择某一个
      specList: [], // 拆分制剂规格list,
      //质量单位概念域
      pubMassUnitConcept:[],
      //体积单位概念域
      pubVolumeUnitConcept:[],
      //国际单位、活性单位概念字典
      pubActiveUnitConcept:[]
    }
  },
  beforeCreate: function () {
    that = this;
  },
 async created() {
    // console.log(this.drugCompositionListData)
   //console.log(this.specModeChoice)
   // console.log('created')
   //console.log(this.specMode)
   // console.log(this.specData)
   // this.$nextTick(() => {
     this.ppSpecBeanList = this.specData
     //console.log(this.specData)
     //console.log(this.ppSpecBeanList.maxConcentration)
     //console.log(this.ppSpecBeanList.concentration)
     if (this.specMode === '2') {
       if (this.ppSpecBeanList.concentration !== null && this.ppSpecBeanList.concentration !== undefined && this.ppSpecBeanList.concentration !== '') {
         this.isConcentrationShow = true
       } else {
         this.isConcentrationShow = false
       }
     }
     this.ppSpecBeanList.specMode = this.specMode
     this.ppSpecBeanList.preparationId = this.preparationId
     // console.log(this.ppSpecBeanList)
     // console.log(this.ppSpecBeanList.descList)
     if(this.ppSpecBeanList.descList && this.ppSpecBeanList.descList.length ===0 ) {
       this.addSubstanceName() // 默认生成规格描述
     }
     this.setPrimary('name')
   // })
   //获取剂型名称数据字典
   await getConcept(conceptConst.PUB_SPEC_PKG_UNIT, 0).then(result => {
     this.specUnitDict = result
     this.specUnitList = result
   })
   // console.log(this.specUnitList)
   /*获取数量类型字典*/
   await getConcept(conceptConst.PUB_MATH_OPERATOR, 0).then(result => {
     // console.log(result)
     this.quantityTypeDict = result
   })
   await this.getConceptsByDomainCodeFn() // 剂量单位
   await this.specModeUnitFn() // 长度/宽度单位

   /*获取质量单位概念字典*/
   await getConcept(conceptConst.PUB_MASS_UNIT, 0).then(result => {
     this.pubMassUnitConcept = result
   })

   /*获取体积单位概念字典*/
   await getConcept(conceptConst.PUB_VOLUME_UNIT, 0).then(result => {
     this.pubVolumeUnitConcept = result
   })

   /*获取国际单位、活性单位概念字典*/
   await getConcept(conceptConst.PUB_ACTIVE_UNIT, 0).then(result => {
     this.pubActiveUnitConcept = result
   })
  },
  // activated() {
  //   if (this.specMode === '2') {
  //     if (this.ppSpecBeanList.concentration !== null && this.ppSpecBeanList.concentration !== undefined && this.ppSpecBeanList.concentration !== '') {
  //       this.isConcentrationShow = true
  //     } else {
  //       this.isConcentrationShow = false
  //     }
  //   }
  // },
  methods: {
    // 规格解析（根据规格描述，拆分出分子、分母数值和单位）
    async splitRuleDescription(val) {
     // console.log(val)
      let obj = {}
      obj.spec = val
      let res = await analyseSpec(obj)
     // console.log(res)
      if(res.data && res.data.length==0){
        return;
      }
      this.specList = res.data
      if(res.data.length==1){
       // console.log(res.data)
        //只匹配到一个规格
        this.ppSpecBeanList.denominatorUnit = res.data[0].denominatorUnit  // 分母单位
        this.ppSpecBeanList.denominatorConstituent = res.data[0].denominatorVal // 分母
        this.ppSpecBeanList.numeratorUnit = res.data[0].numeratorUnit // 分子单位
        this.ppSpecBeanList.maxNumeratorConstituent = res.data[0].numeratorVal // 分子
      }else{
        //显示选择规格弹窗
        this.specSelectDialogVisible=true
      }
    },
    selectSpec(item) { // 多个规格选择一个
      this.ppSpecBeanList.denominatorUnit = item.denominatorUnit  // 分母单位
      this.ppSpecBeanList.denominatorConstituent = item.denominatorVal // 分母
      this.ppSpecBeanList.numeratorUnit = item.numeratorUnit // 分子单位
      this.ppSpecBeanList.maxNumeratorConstituent = item.numeratorVal // 分子
      //关闭选择规格弹窗
      this.specSelectDialogVisible = false
    },
    // 切换规格模式的时候清空表格数据
    specModeChange(val) {
      //console.log(val)
      // this.$refs.ppSpecBeanListref.resetFields()
      // this.ppSpecBeanList.specGroupKey = Math.random().toString()
      // this.ppSpecBeanList.convertFactor = null
      // this.ppSpecBeanList.denominatorConstituent = null
      // this.ppSpecBeanList.denominatorUnit= null
      // this.ppSpecBeanList.denominatorUnitStr= null
      // this.ppSpecBeanList.descList= [] // 规格描述的集合
      // this.ppSpecBeanList.id= null
      // this.ppSpecBeanList.isDefault= true //默认为首选规格
      // this.ppSpecBeanList.maxConcentration= null
      // this.ppSpecBeanList.maxNumeratorConstituent= null
      // this.ppSpecBeanList.minConcentration= null
      // this.ppSpecBeanList.minNumeratorConstituent= null
      // this.ppSpecBeanList.numeratorType= 101801
      // this.ppSpecBeanList.numeratorTypeStr= null
      // this.ppSpecBeanList.numeratorUnit= null
      // this.ppSpecBeanList.preparationId= null
      // this.ppSpecBeanList.specId= null
      // this.ppSpecBeanList.specUnit= null
      // this.ppSpecBeanList.specUnitStr= null
      // this.ppSpecBeanList.status = '0'
      // this.ppSpecBeanList.showSpec= null
      // this.ppSpecBeanList.namePrimary= 0
      // if(this.ppSpecBeanList.descList.length ===0 ) {
      //   this.addSubstanceName() // 默认生成规格描述
      // }
      // this.setPrimary('name')
      // this.ppSpecBeanList = DEEP_CLONE(PPSPECBEANLIST_DATA)
    },
    /* 规格单位--下拉搜索过滤*/
   specUnitFilter(query){
      if (query !== '') {
        this.specUnitList = this.specUnitDict.filter(item => {
          return item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1;
        });

      } else {
        this.specUnitList =this.specUnitDict;
      }
    },
    /* 剂量单位--下拉搜索过滤*/
    unitFilter_jl(query){
      if (query !== '') {
        this.preparationUnitList_jl = this.preparationUnitDict.filter(item => {
          let flag= item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if(item.aliasList){
            item.aliasList.some(aliasItem=>{
              if((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase())>-1)
              ){
                flag=true
                return true
              }
            })
          }
          return flag
        });

      } else {
        this.preparationUnitList_jl =this.preparationUnitDict;
      }
    },
    /* 分子单位--下拉搜索过滤*/
    unitFilter_fz(query){
      if (query !== '') {
        this.preparationUnitList_fz = this.preparationUnitDict.filter(item => {
          let flag= item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if(item.aliasList){
            item.aliasList.some(aliasItem=>{
              if((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase())>-1)
              ){
                flag=true
                return true
              }
            })
          }
          return flag
        });

      } else {
        this.preparationUnitList_fz =this.preparationUnitDict;
      }
    },
    /* 分母单位--下拉搜索过滤高级搜索*/
    unitFilter_fm(query){
      if (query !== '') {
        this.preparationUnitList_fm = this.preparationUnitDict.filter(item => {
          let flag= item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if(item.aliasList){
            item.aliasList.some(aliasItem=>{
              if((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase())>-1)
              ){
                flag=true
                return true
              }
            })
          }
          return flag
        });

      } else {
        this.preparationUnitList_fm =this.preparationUnitDict;
      }
    },
    /* 剂量单位--下拉搜索过滤*/
    unitFilter_fm_ordinary(query){
      if (query !== '') {
        this.preparationUnitList_fm_ordinary = this.preparationUnitDict.filter(item => {
          let flag= item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if(item.aliasList){
            item.aliasList.some(aliasItem=>{
              if((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase())>-1)
              ){
                flag=true
                return true
              }
            })
          }
          return flag
        });

      } else {
        this.preparationUnitList_fm_ordinary =this.preparationUnitDict;
      }
    },
    async getConceptsByDomainCodeFn() {
      let obj = {}
      obj.domainCode = 'pub_mass_unit,pub_volume_unit,pub_bq_unit,pub_length_unit,pub_spec_pkg_unit,pub_thermal_unit,pub_active_unit'
      obj.includeSon = 0
      let res = await getConceptsByDomainCode(obj) // 剂量单位（规格新增下拉数据集）
      this.preparationUnitList = res.data
      this.preparationUnitList_fz = res.data
      this.preparationUnitList_jl = res.data
      this.preparationUnitList_fm = res.data
      this.preparationUnitList_fm_ordinary = res.data
      this.preparationUnitDict = res.data
    },
    // 长度单位list
    // lengthUnit
    async specModeUnitFn() {
      let res = await specModeUnit()
      console.log(res)
      if (res && res.code===200) {
        this.lengthUnit = res.data
      }
    },
    // 浓度正数，保留两位小数
    oninput(num) {
      var str = num
      var len1 = str.substr(0, 1)
      var len2 = str.substr(1, 1)
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1)
      }
      //第一位不能是.
      if (len1 == ".") {
        str = ""
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1)
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
        }
      }
      //正则替换
      str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
      str = str.replace(/\.\d\d\d\d$/,'') // 小数点后只能输三位
      return str
    },
    // 重置form
    resetForm() {
      this.$refs.ppSpecBeanListref.resetFields()
      // this.$nextTick(() => {
      //   this.$refs.ppSpecBeanListref.resetFields()
      // })
    },
    primaryChange(value, type, index) {
      // console.log(value, type, index)
      let field = type === 'name' ? 'descList' : ''
      let primaryField = type === 'name' ? 'namePrimary' : ''
      // console.log(this.ppSpecBeanList[primaryField])
      // console.log(this.ppSpecBeanList[field])
      this.ppSpecBeanList[primaryField] = index
      // console.log(this.ppSpecBeanList[primaryField])
      this.ppSpecBeanList[field].forEach(function(item, i) {
        if (i === index) {
          item.isDefault = true
        } else {
          item.isDefault = false
        }
      })
      this.$forceUpdate()
      // console.log(this.ppSpecBeanList[field])
    },
    saveSpec(formName) {
      this.isSameSpec = false
      this.$refs.ppSpecBeanListref.validate(async valid => {
        // this.$refs[formName].validate(async valid => {
        //console.log(valid)
        // console.log(this.ppSpecBeanList)

        if (valid) {
          if (this.ppSpecBeanList.specMode === '2') { // 质量/体积等
            let obj = {}
            obj.numeratorVal = this.ppSpecBeanList.maxNumeratorConstituent
            obj.numeratorUnit = this.ppSpecBeanList.numeratorUnit
            obj.denominatorVal = this.ppSpecBeanList.denominatorConstituent
            obj.denominatorUnit = this.ppSpecBeanList.denominatorUnit
            let res_concentration = await getConcentration(obj)
            //console.log(res_concentration)
            if (res_concentration.code === 200) {
              //console.log(res_concentration.data)
              let conData = res_concentration.data
              //console.log(conData)
              //console.log(typeof conData)
              //console.log(typeof this.ppSpecBeanList.maxConcentration)
              //console.log(this.ppSpecBeanList.maxConcentration)
              let maxConcentration=this.ppSpecBeanList.maxConcentration
              if (!isEmpty(maxConcentration) && maxConcentration != conData) {
                /*this.$message({
                  message: '浓度数据输入审核报错，请校验！',
                  type: 'warning'
                })*/
                this.msgError('浓度['+maxConcentration+']与实际计算浓度['+conData+']不一致，无法保存')
                return false
              }
            } else {
              this.$message({
                message: '浓度数据输入有误，请校验！',
                type: 'warning'
              })
              return false
            }
          } else if (this.ppSpecBeanList.specMode === '3') { // 尺寸
            let obj = {}
            obj.specLength = this.ppSpecBeanList.specLength
            obj.specLengthUnit = this.ppSpecBeanList.specLengthUnit
            obj.specWidth = this.ppSpecBeanList.specWidth
            obj.specWidthUnit = this.ppSpecBeanList.specWidthUnit
          } else if (this.ppSpecBeanList.specMode === '9') { // 高级
            if (this.ppSpecBeanList.numeratorType === 101807) { // ~
              // 浓度下限
              let obj_low = {}
              obj_low.numeratorVal = this.ppSpecBeanList.minNumeratorConstituent
              obj_low.numeratorUnit = this.ppSpecBeanList.numeratorUnit
              obj_low.denominatorVal = this.ppSpecBeanList.denominatorConstituent
              obj_low.denominatorUnit = this.ppSpecBeanList.denominatorUnit
              let res_concentration_low = await getConcentration(obj_low)
              //console.log(res_concentration_low)
              if (res_concentration_low.code === 200) {
                //console.log(res_concentration_low.data)
                let conData_low = res_concentration_low.data
                //console.log(conData_low)
                //console.log(typeof conData_low)
                //console.log(typeof this.ppSpecBeanList.minConcentration)
                //console.log(this.ppSpecBeanList.minConcentration)
                let minConcentration=this.ppSpecBeanList.minConcentration
                if (!isEmpty(minConcentration) && minConcentration != conData_low) {
                  this.msgError('浓度下限['+minConcentration+']与实际计算浓度['+conData_low+']不一致，无法保存')
                  return false
                }
              } else {
                this.$message({
                  message: '浓度下限数据输入有误，请校验！',
                  type: 'warning'
                })
                return false
              }
              // 浓度上限
              let obj = {}
              obj.numeratorVal = this.ppSpecBeanList.maxNumeratorConstituent
              obj.numeratorUnit = this.ppSpecBeanList.numeratorUnit
              obj.denominatorVal = this.ppSpecBeanList.denominatorConstituent
              obj.denominatorUnit = this.ppSpecBeanList.denominatorUnit
              let res_concentration = await getConcentration(obj)
              //console.log(res_concentration)
              if (res_concentration.code === 200) {
                //console.log(res_concentration.data)
                let conData = res_concentration.data
                //console.log(conData)
                //console.log(typeof conData)
                //console.log(typeof this.ppSpecBeanList.maxConcentration)
                //console.log(this.ppSpecBeanList.maxConcentration)
                let maxConcentration=this.ppSpecBeanList.maxConcentration
                if (!isEmpty(maxConcentration) && maxConcentration != conData) {
                  this.msgError('浓度上限['+maxConcentration+']与实际计算浓度['+conData+']不一致，无法保存')
                  return false
                }
              } else {
                this.$message({
                  message: '浓度上限数据输入有误，请校验！',
                  type: 'warning'
                })
                return false
              }
            } else {
              let obj = {}
              obj.numeratorVal = this.ppSpecBeanList.maxNumeratorConstituent
              obj.numeratorUnit = this.ppSpecBeanList.numeratorUnit
              obj.denominatorVal = this.ppSpecBeanList.denominatorConstituent
              obj.denominatorUnit = this.ppSpecBeanList.denominatorUnit
              let res_concentration = await getConcentration(obj)
              //console.log(res_concentration)
              if (res_concentration.code === 200) {
                //console.log(res_concentration.data)
                let conData = res_concentration.data
                //console.log(conData)
                //console.log(typeof conData)
                //console.log(typeof this.ppSpecBeanList.maxConcentration)
                //console.log(this.ppSpecBeanList.maxConcentration)
                let maxConcentration=this.ppSpecBeanList.maxConcentration
                if (!isEmpty(maxConcentration) && maxConcentration != conData) {
                  this.msgError('浓度['+maxConcentration+']与实际计算浓度['+conData+']不一致，无法保存')
                  return false
                }
              } else {
                this.$message({
                  message: '浓度数据输入有误，请校验！',
                  type: 'warning'
                })
                return false
              }
            }

          }
          // console.log(this.ppSpecBeanList.preparationId)
          if (this.ppSpecBeanList.specMode === '9') {
            // console.log('**************5***************')
            // console.log(this.preparationUnitList)
            let aa = '' // 分子类型
            this.preparationUnitList_fz.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.numeratorUnit) {
                // console.log(item.conceptVal)
                aa = item.conceptVal
              }
            })
            let cc = '' // 分母类型
            this.preparationUnitList_fm_ordinary.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.denominatorUnit) {
                // console.log(item.conceptVal)
                cc = item.conceptVal
              }
            })
            let bb = '' // 规格类型
            // console.log(this.specUnitList)
            // console.log(this.ppSpecBeanList.specUnit)
            this.specUnitList.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.specUnit) {
                // console.log(item.conceptVal)
                bb = item.conceptVal
              }
            })
            let dd = '' // 浓度上限
            if (this.ppSpecBeanList.maxConcentration === null) {
              dd = ''
            } else {
              dd = '(' + this.ppSpecBeanList.maxConcentration + '%)'
            }
            let ee = '' // 浓度下限
            if (this.ppSpecBeanList.minConcentration === null) {
              ee = ''
            } else {
              ee = '(' + this.ppSpecBeanList.minConcentration + '%)'
            }
            // console.log(dd)
            // 统一规格单位不要
            if (this.ppSpecBeanList.numeratorType === 101801) { // 分子类型为 =
              // 分子上限 分子单位 ： 分母 分母单位 / 规格单位 (浓度%)
              // ppSpecBeanList.maxNumeratorConstituent ppSpecBeanList.numeratorUnit｜unitChoice : ppSpecBeanList.denominatorConstituent ppSpecBeanList.denominatorUnit|unitChoice /ppSpecBeanList.specUnit| specChoice ( ppSpecBeanList.maxConcentration %)
              this.ppSpecBeanList.showSpec = this.ppSpecBeanList.maxNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + dd
              // console.log(this.ppSpecBeanList.showSpec)
            } else if (this.ppSpecBeanList.numeratorType === 101803) { // 分子类型为 >
              // 分子上限 分子单位 ： 分母 分母单位 / 规格单位 (浓度%)
              // ppSpecBeanList.maxNumeratorConstituent ppSpecBeanList.numeratorUnit｜unitChoice : ppSpecBeanList.denominatorConstituent ppSpecBeanList.denominatorUnit|unitChoice /ppSpecBeanList.specUnit| specChoice ( ppSpecBeanList.maxConcentration %)
              this.ppSpecBeanList.showSpec = '>' + this.ppSpecBeanList.maxNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + dd
              // console.log(this.ppSpecBeanList.showSpec)
            } else if (this.ppSpecBeanList.numeratorType === 101804) { // 分子类型为 >=
              this.ppSpecBeanList.showSpec = '>=' + this.ppSpecBeanList.maxNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + dd
              // console.log(this.ppSpecBeanList.showSpec)
            } else if (this.ppSpecBeanList.numeratorType === 101805) { // 分子类型为 <
              this.ppSpecBeanList.showSpec = '<' + this.ppSpecBeanList.maxNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + dd
              // console.log(this.ppSpecBeanList.showSpec)
            } else if (this.ppSpecBeanList.numeratorType === 101806) { // 分子类型为 <=
              this.ppSpecBeanList.showSpec = '<=' + this.ppSpecBeanList.maxNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + dd
              // console.log(this.ppSpecBeanList.showSpec)
            } else if (this.ppSpecBeanList.numeratorType === 101807) { // 分子类型为 ～
              // 分子下限 分子单位 ： 分母 分母单位 / 规格单位 (浓度下限%) ~ 分子上限 分子单位 ： 分母 分母单位 / 规格单位 (浓度上限%)
              this.ppSpecBeanList.showSpec = this.ppSpecBeanList.minNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + ee + '～' + this.ppSpecBeanList.maxNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + dd
              // console.log(this.ppSpecBeanList.showSpec)
            }
          } else if (this.ppSpecBeanList.specMode === '3') { // 尺寸
            let aa = '' // 长
            this.lengthUnit.forEach((item) => {
              if (item.code === this.ppSpecBeanList.specLengthUnit) {
                // console.log(item.conceptVal)
                aa = item.name
              }
            })
            let bb = '' // 宽
            this.lengthUnit.forEach((item) => {
              if (item.code === this.ppSpecBeanList.specWidthUnit) {
                // console.log(item.conceptVal)
                bb = item.name
              }
            })
            this.ppSpecBeanList.showSpec = this.ppSpecBeanList.specLength + aa + '×' + this.ppSpecBeanList.specWidth + bb
          } else if (this.ppSpecBeanList.specMode === '2') {
            // {{ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}:{{ppSpecBeanList.denominatorConstituent}}{{ppSpecBeanList.denominatorUnit |unitChoice}}({{ ppSpecBeanList.maxConcentration }})
            // console.log(this.preparationUnitList)
            let aa = '' // 分子
            this.preparationUnitList_fz.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.numeratorUnit) {
                // console.log(item.conceptVal)
                aa = item.conceptVal
              }
            })
            let cc = '' // 分母
            this.preparationUnitList_fm.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.denominatorUnit) {
                // console.log(item.conceptVal)
                cc = item.conceptVal
              }
            })
            let bb = ''
            // console.log(this.specUnitList)
            // console.log(this.ppSpecBeanList.specUnit)
            this.specUnitList.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.specUnit) {
                // console.log(item.conceptVal)
                bb = item.conceptVal
              }
            })
            let dd = '' // 浓度上限
            if (this.ppSpecBeanList.maxConcentration === null) {
              dd = ''
            } else {
              dd = '(' + this.ppSpecBeanList.maxConcentration + '%)'
            }
            this.ppSpecBeanList.showSpec = this.ppSpecBeanList.maxNumeratorConstituent + aa + ':' + this.ppSpecBeanList.denominatorConstituent + cc + dd
            // console.log('**************67810***************')
            // console.log(this.ppSpecBeanList.showSpec)
          } else {
            // console.log('**************12349***************')
            // ppSpecBeanList.maxNumeratorConstituent}}{{ppSpecBeanList.numeratorUnit | unitChoice }}/{{ppSpecBeanList.specUnit | specChoice
            // console.log(this.preparationUnitList)
            let aa = '' // 剂量
            this.preparationUnitList_jl.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.numeratorUnit) {
                // console.log(item.conceptVal)
                aa = item.conceptVal
              }
            })
            let bb = ''
            // console.log(this.specUnitList)
            this.specUnitList.forEach((item) => {
              if (item.conceptId === this.ppSpecBeanList.specUnit) {
                // console.log(item.conceptVal)
                bb = item.conceptVal
              }
            })
            // this.ppSpecBeanList.showSpec = this.ppSpecBeanList.maxNumeratorConstituent + aa + '/' + bb
            this.ppSpecBeanList.showSpec = this.ppSpecBeanList.maxNumeratorConstituent + aa
            // console.log(this.ppSpecBeanList.showSpec)
          }

          if (this.ppSpecBeanList.preparationId) {
           // console.log(this.isEdit)
           // console.log(this.isEdit === true)
            if (this.isEdit === true) {
              let res_check = await checkExistSameSpec(this.specTableData)
              //console.log(res_check)
              if (res_check.code === 200) {
                if (res_check.data.length > 0) {
                  let showSpec = res_check.data[0].showSpec
                  this.$confirm('存在相同制剂规格【'+ showSpec +'】，继续保存?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.isSameSpec = false
                    this.savePpSpec()
                    this.$emit('closeAddSF')
                  }).catch(() => {
                    this.isSameSpec = true
                    return false
                  });
                  return false
                } else {
                  this.isSameSpec = false
                }
              }
              // console.log('/**修改*/')
              if (this.ppSpecBeanList.specMode == 1) { //质量（w）、体积（v）、国际单位（IU）、复方、草药
                let obj = DEEP_CLONE(this.ppSpecBeanList)
                obj.denominatorConstituent = null
                obj.denominatorUnit = null
                obj.convertFactor = null
                obj.maxConcentration = null
                obj.numeratorType = 101803
                // console.log(obj)
                let res = await modifySpec(obj)
                // console.log(res)
                if (res.code === 200) {
                  this.msgSuccess('修改成功！')
                }
              } else {
                let res = await modifySpec(this.ppSpecBeanList)
                // console.log(res)
                if (res.code === 200) {
                  this.msgSuccess('修改成功！')
                }
              }

            } else {
              // console.log('/**新增*/')
              // this.ppSpecBeanList = DEEP_CLONE(PPSPECBEANLIST_DATA)
              this.ppSpecBeanList.preparationId = this.preparationId
              // // console.log(this.ppSpecBeanList.preparationId)
              // let res = await addSpec(this.ppSpecBeanList)
              // // console.log(res)
              // if (res.code === 200) {
              //   this.msgSuccess('新增成功！')
              // }
              this.specOriginalData = DEEP_CLONE(this.specTableData)
              //console.log(this.specOriginalData)
              this.specOriginalData.push(this.ppSpecBeanList)
              // console.log(this.ppSpecBeanList)
              let res_check = await checkExistSameSpec(this.specOriginalData)
             // console.log(res_check)
              if (res_check.code === 200) {
                if (res_check.data.length > 0) {
                  let showSpec = res_check.data[0].showSpec
                  this.$confirm('存在相同制剂规格【'+ showSpec +'】，继续保存?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.isSameSpec = false
                    this.savePpSpec()
                    this.$emit('closeAddSF')
                  }).catch(() => {
                    this.isSameSpec = true
                    return false
                  });
                  return false
                } else {
                  this.isSameSpec = false
                }
              }
            }
            this.ppSpecBeanList.specGroupKey = this.specGroupKey ? this.specGroupKey : Math.random().toString()
            if (this.ppSpecBeanList.specMode == 1) { //质量（w）、体积（v）、国际单位（IU）、复方、草药
              let obj = DEEP_CLONE(this.ppSpecBeanList)
              obj.denominatorConstituent = null
              obj.denominatorUnit = null
              obj.convertFactor = null
              obj.maxConcentration = null
              obj.numeratorType = 101803
              this.$emit('tableValue', obj)
            } else {
              this.$emit('tableValue', this.ppSpecBeanList)
            }
          } else { // 制剂为创建之前新增修改
            if (this.ppSpecBeanList.specMode === '9') {
              this.ppSpecBeanList.numeratorType = this.ppSpecBeanList.numeratorType
            } else {
              this.ppSpecBeanList.numeratorType = 1
              // this.ppSpecBeanList.numeratorTypeStr = '等于'
            }
            this.ppSpecBeanList.specGroupKey = this.specGroupKey ? this.specGroupKey : Math.random().toString()
            if (this.isEdit === true) {
             // console.log('/**修改*/')
              let res_check = await checkExistSameSpec(this.specTableData)
             // console.log(res_check)
              if (res_check.code === 200) {
                if (res_check.data.length > 0) {
                  let showSpec = res_check.data[0].showSpec
                  this.$confirm('存在相同制剂规格【'+ showSpec +'】，继续保存?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.isSameSpec = false
                    this.savePpSpec()
                    this.$emit('closeAddSF')
                  }).catch(() => {
                    this.isSameSpec = true
                    return false
                  });
                  return false
                } else {
                  this.isSameSpec = false
                }
              }
            } else {
             // console.log('/**新增*/')
              this.specOriginalData = DEEP_CLONE(this.specTableData)
              this.specOriginalData.push(this.ppSpecBeanList)
              let res_check = await checkExistSameSpec(this.specOriginalData)
              //console.log(res_check)
              if (res_check.code === 200) {
                if (res_check.data.length > 0) {
                  let showSpec = res_check.data[0].showSpec
                  this.$confirm('存在相同制剂规格【'+ showSpec +'】，继续保存?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.isSameSpec = false
                    this.savePpSpec()
                    this.$emit('closeAddSF')
                  }).catch(() => {
                    this.isSameSpec = true
                    return false
                  });
                  return false
                } else {
                  this.isSameSpec = false
                }
              }
            }
            // console.log('/***************specGroupKey***************/')
            // console.log(this.specGroupKey)

            // console.log(this.ppSpecBeanList.specGroupKey)
            // console.log(this.ppSpecBeanList)
            // console.log(this.specOriginalData)
            // this.specOriginalData.push(this.ppSpecBeanList)
            this.savePpSpec()
          }
          //console.log(this.ppSpecBeanList)
          //console.log(this.specModeChoice)
          // this.specModeChoice = false
          this.$emit('closeAddSF')
        }
      })
    },
    savePpSpec(){
      if (this.ppSpecBeanList.specMode == 1) { //质量（w）、体积（v）、国际单位（IU）、复方、草药
        let obj = DEEP_CLONE(this.ppSpecBeanList)
        obj.denominatorConstituent = null
        obj.denominatorUnit = null
        obj.convertFactor = null
        obj.maxConcentration = null
        obj.numeratorType = 101803
        this.$emit('tableValue', obj)
      } else {
        this.$emit('tableValue', this.ppSpecBeanList)
      }
    },
    // 关闭弹窗
    closeAddSF() {
      this.$emit('closeAddSF')
      /*if (this.isSameSpec === false) {

        this.$emit('closeAddSF')
      } else {
        this.$message({
          message: '存在相同制剂规格,请修改之后再关闭！',
          type: 'warning'
        })
      }*/
    },
    /*添加制剂名称输入表单组件*/
    addSubstanceName() {
      // console.log(this.ppSpecBeanList)
      // console.log(this.ppSpecBeanList.descList)
      // // console.log(this.ppSpecBeanList.descList.length == 0)
      this.ppSpecBeanList.descList.push({
        specDesc: '',
        id: null,
        specId: null,
        // isDefault: true
        isDefault: this.ppSpecBeanList.descList.length == 0 ? true : false
      })
      if (this.ppSpecBeanList.descList.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.ppSpecBeanList.namePrimary = 0
      }

    },
    /*移除制剂名称输入表单组件*/
    delSubstanceName(item) {
      var index = this.ppSpecBeanList.descList.indexOf(item)
      if (this.ppSpecBeanList.descList.length > 1) {
        this.ppSpecBeanList.descList.splice(index, 1)
      } else {
        this.$message('规格描述至少保留一个！')
      }
      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      this.ppSpecBeanList.descList.forEach(function(item, index) {
        if (item.isDefault === true) {
          primary = index
          return
        }
      })
      if (primary === -1) {
        //删除后没有默认值了
        this.ppSpecBeanList.descList[0].isDefault = true
        this.ppSpecBeanList.namePrimary = 0
      }

    },
    /*设置首选项单选按钮*/
    setPrimary(type) {
      let field = type === 'name' ? 'descList' : ''
      let primaryField = type === 'name' ? 'namePrimary' : ''
      let preparation = this.ppSpecBeanList
      if(preparation && preparation[field]){
        preparation[field].forEach(function(item, index) {
          if (item.isDefault === true) {
            preparation[primaryField] = index
            return
          }
        })
      }
    },
    async getList(value) {
      let res = await dict(value)
      return res.data
    },
    // 规格模式校验
    validateSpecMode(rule, value, callback){
      if(this.ppSpecBeanList.specMode!='2'){
        callback()
      }else{
        // numeratorUnit 剂量单位  103303   质量单位 pubMassUnitConcept
        // denominatorUnit  分母数量单位 103402   体积单位 pubVolumeUnitConcept


        //分子体积单位
        let flag1v=this.pubVolumeUnitConcept.some(conceptItem=>{
          return conceptItem.conceptId==this.ppSpecBeanList.numeratorUnit
        })

       //分母质量单位
        let flag2w=this.pubMassUnitConcept.some(conceptItem=>{
          return conceptItem.conceptId==this.ppSpecBeanList.denominatorUnit
        })

        if(flag1v && flag2w){
          callback(new Error('不支持的规格模式[体积/质量]！,请修改为[质量/体积]'))
        }else{
          callback()
        }

      }

    }
  }
}
</script>

<style  lang="scss" scoped>
  .specificationMode {
    margin-left:7px;
    width: 100%;
    .el-col-5 {
      height: 30px;
      line-height: 30px;
    }

  }
  /*删除按钮*/
  .el-icon-remove {
    color: #F52C2C;
    font-size: 20px;
    padding: 0px 6px;
  }
  // 显示规格
  .spanFontSize{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #2B60F8;
  }
  .el-form-item{
    margin-bottom: 30px;
  }
  //.el-form-item__label{
  //  padding:0px!important;
  //}
</style>
<style lang="scss">
  .addSpec{
    .el-form-item__label{
      padding:0px!important;
    }
    .el-dialog--center .el-dialog__body{
      padding-left:0px!important;
      padding-bottom:0px!important;
    }
    .el-col-6{
      padding:0px!important;
    }
  }

</style>
