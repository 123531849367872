// 新增-更新制剂
export const PREPARATION_DATA = {
  drugId: null,
  drugProcMethod: null,
  drugTypeSpecs: null,
  concentration:null, // 浓度
  ppRouteBeanList: [], // 用药途径列表
  ppSiteBeanList: [], // 给药部位列表
  ppSpecBeanList: [], // 	制剂规格列表
  preparationAliasList: [], // 制剂别名
  preparationId: null,
  type: null,
  namePrimary: 0
}
