var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      staticClass: "addSpec",
      attrs: {
        visible: _vm.visible,
        "before-close": _vm.closeAddSF,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        title: "添加规格",
        center: "",
        width: "1010px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ppSpecBeanListref",
          attrs: {
            model: _vm.ppSpecBeanList,
            rules: _vm.preparationRules,
            "label-width": "125px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规格模式：",
                prop: "specMode",
                rules: [{ validator: _vm.validateSpecMode }],
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "specificationMode",
                  on: { change: _vm.specModeChange },
                  model: {
                    value: _vm.ppSpecBeanList.specMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.ppSpecBeanList, "specMode", $$v)
                    },
                    expression: "ppSpecBeanList.specMode",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 22 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "12px 0px" },
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: "1",
                                disabled: _vm.specModeChoice,
                              },
                            },
                            [
                              _vm._v(
                                "质量（w）、体积（v）、国际单位（IU）、复方、草药"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "12px 0px" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("el-radio", { attrs: { label: "9" } }, [
                            _vm._v("高级模式"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 22 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "12px 0px" },
                          attrs: { span: 24 },
                        },
                        [
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v(
                              "质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 22 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "12px 0px" },
                          attrs: { span: 24 },
                        },
                        [
                          _c("el-radio", { attrs: { label: "3" } }, [
                            _vm._v("尺寸（长度×宽度）"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ppSpecBeanList.specMode === "9"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分子类型：",
                            prop: "numeratorType",
                            rules: [
                              {
                                required: true,
                                message: "请输入分子类型",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择分子类型" },
                              model: {
                                value: _vm.ppSpecBeanList.numeratorType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ppSpecBeanList,
                                    "numeratorType",
                                    $$v
                                  )
                                },
                                expression: "ppSpecBeanList.numeratorType",
                              },
                            },
                            _vm._l(_vm.quantityTypeDict, function (item) {
                              return _c("el-option", {
                                key: "quantityTypeDict" + item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ppSpecBeanList.numeratorType === 101807
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分子数量(下限)：",
                                prop: "minNumeratorConstituent",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入内容" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    _vm.ppSpecBeanList.minNumeratorConstituent =
                                      _vm.oninput(
                                        _vm.ppSpecBeanList
                                          .minNumeratorConstituent
                                      )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.ppSpecBeanList.minNumeratorConstituent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ppSpecBeanList,
                                      "minNumeratorConstituent",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ppSpecBeanList.minNumeratorConstituent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分子数量(上限)：",
                            prop: "maxNumeratorConstituent",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入内容" },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ppSpecBeanList.maxNumeratorConstituent =
                                  _vm.oninput(
                                    _vm.ppSpecBeanList.maxNumeratorConstituent
                                  )
                              },
                            },
                            model: {
                              value: _vm.ppSpecBeanList.maxNumeratorConstituent,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ppSpecBeanList,
                                  "maxNumeratorConstituent",
                                  $$v
                                )
                              },
                              expression:
                                "ppSpecBeanList.maxNumeratorConstituent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分子数量单位：",
                            prop: "numeratorUnit",
                            rules: [
                              {
                                required: true,
                                message: "请输入分子数量单位",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择分子数量单位",
                                filterable: "",
                                "filter-method": _vm.unitFilter_fz,
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.preparationUnitList_fz =
                                    _vm.preparationUnitDict
                                },
                              },
                              model: {
                                value: _vm.ppSpecBeanList.numeratorUnit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ppSpecBeanList,
                                    "numeratorUnit",
                                    $$v
                                  )
                                },
                                expression: "ppSpecBeanList.numeratorUnit",
                              },
                            },
                            _vm._l(_vm.preparationUnitList_fz, function (item) {
                              return _c("el-option", {
                                key: item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ppSpecBeanList.specMode === "9"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分母数量：",
                            prop: "denominatorConstituent",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入内容" },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ppSpecBeanList.denominatorConstituent =
                                  _vm.oninput(
                                    _vm.ppSpecBeanList.denominatorConstituent
                                  )
                              },
                            },
                            model: {
                              value: _vm.ppSpecBeanList.denominatorConstituent,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ppSpecBeanList,
                                  "denominatorConstituent",
                                  $$v
                                )
                              },
                              expression:
                                "ppSpecBeanList.denominatorConstituent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分母数量单位：",
                            prop: "denominatorUnit",
                            rules: [
                              {
                                required: true,
                                message: "请输入分母数量单位",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择分母数量单位",
                                filterable: "",
                                "filter-method": _vm.unitFilter_fm,
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.preparationUnitList_fm =
                                    _vm.preparationUnitDict
                                },
                              },
                              model: {
                                value: _vm.ppSpecBeanList.denominatorUnit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ppSpecBeanList,
                                    "denominatorUnit",
                                    $$v
                                  )
                                },
                                expression: "ppSpecBeanList.denominatorUnit",
                              },
                            },
                            _vm._l(_vm.preparationUnitList_fm, function (item) {
                              return _c("el-option", {
                                key: item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "换算系数：", prop: "convertFactor" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入内容" },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ppSpecBeanList.convertFactor = _vm.oninput(
                                  _vm.ppSpecBeanList.convertFactor
                                )
                              },
                            },
                            model: {
                              value: _vm.ppSpecBeanList.convertFactor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ppSpecBeanList,
                                  "convertFactor",
                                  $$v
                                )
                              },
                              expression: "ppSpecBeanList.convertFactor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "规格单位：",
                                "label-width": "95px",
                                prop: "specUnit",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入规格单位",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择规格单位",
                                    filterable: "",
                                    "filter-method": _vm.specUnitFilter,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.specUnitList = _vm.specUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.specUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "specUnit",
                                        $$v
                                      )
                                    },
                                    expression: "ppSpecBeanList.specUnit",
                                  },
                                },
                                _vm._l(_vm.specUnitList, function (item) {
                                  return _c("el-option", {
                                    key: item.conceptId,
                                    attrs: {
                                      label: item.conceptVal,
                                      value: item.conceptId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.ppSpecBeanList.specMode === "9"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm.ppSpecBeanList.numeratorType === 101807
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "浓度(下限)：",
                                prop: "minConcentration",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请输入内容" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      _vm.ppSpecBeanList.minConcentration =
                                        _vm.oninput(
                                          _vm.ppSpecBeanList.minConcentration
                                        )
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.minConcentration,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "minConcentration",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ppSpecBeanList.minConcentration",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticStyle: {
                                        "font-style": "normal",
                                        "margin-right": "10px",
                                        color: "#333333",
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    },
                                    [_vm._v("%")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "浓度(上限)：",
                            prop: "maxConcentration",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入内容" },
                              nativeOn: {
                                keyup: function ($event) {
                                  _vm.ppSpecBeanList.maxConcentration =
                                    _vm.oninput(
                                      _vm.ppSpecBeanList.maxConcentration
                                    )
                                },
                              },
                              model: {
                                value: _vm.ppSpecBeanList.maxConcentration,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ppSpecBeanList,
                                    "maxConcentration",
                                    $$v
                                  )
                                },
                                expression: "ppSpecBeanList.maxConcentration",
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticStyle: {
                                    "font-style": "normal",
                                    "margin-right": "10px",
                                    color: "#333333",
                                  },
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("%")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ppSpecBeanList.specMode !== "9"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm.ppSpecBeanList.specMode !== "3"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "剂量：",
                                prop: "maxNumeratorConstituent",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入内容" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    _vm.ppSpecBeanList.maxNumeratorConstituent =
                                      _vm.oninput(
                                        _vm.ppSpecBeanList
                                          .maxNumeratorConstituent
                                      )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.ppSpecBeanList.maxNumeratorConstituent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ppSpecBeanList,
                                      "maxNumeratorConstituent",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ppSpecBeanList.maxNumeratorConstituent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode !== "3"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "剂量单位：",
                                prop: "numeratorUnit",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入剂量单位",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择剂量单位",
                                    filterable: "",
                                    "filter-method": _vm.unitFilter_jl,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.preparationUnitList_jl =
                                        _vm.preparationUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.numeratorUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "numeratorUnit",
                                        $$v
                                      )
                                    },
                                    expression: "ppSpecBeanList.numeratorUnit",
                                  },
                                },
                                _vm._l(
                                  _vm.preparationUnitList_jl,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode === "3"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "长度：", prop: "specLength" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入内容" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    _vm.ppSpecBeanList.specLength = _vm.oninput(
                                      _vm.ppSpecBeanList.specLength
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ppSpecBeanList.specLength,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ppSpecBeanList,
                                      "specLength",
                                      $$v
                                    )
                                  },
                                  expression: "ppSpecBeanList.specLength",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode === "3"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "长度单位：",
                                prop: "specLengthUnit",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入长度单位",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择长度单位",
                                    filterable: "",
                                    "filter-method": _vm.unitFilter_jl,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.preparationUnitList_jl =
                                        _vm.preparationUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.specLengthUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "specLengthUnit",
                                        $$v
                                      )
                                    },
                                    expression: "ppSpecBeanList.specLengthUnit",
                                  },
                                },
                                _vm._l(_vm.lengthUnit, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode === "3"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "宽度：", prop: "specWidth" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入内容" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    _vm.ppSpecBeanList.specWidth = _vm.oninput(
                                      _vm.ppSpecBeanList.specWidth
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ppSpecBeanList.specWidth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ppSpecBeanList,
                                      "specWidth",
                                      $$v
                                    )
                                  },
                                  expression: "ppSpecBeanList.specWidth",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode === "3"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "宽度单位：",
                                prop: "specWidthUnit",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入宽度单位",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择宽度单位",
                                    filterable: "",
                                    "filter-method": _vm.unitFilter_jl,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.preparationUnitList_jl =
                                        _vm.preparationUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.specWidthUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "specWidthUnit",
                                        $$v
                                      )
                                    },
                                    expression: "ppSpecBeanList.specWidthUnit",
                                  },
                                },
                                _vm._l(_vm.lengthUnit, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode === "2"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分母数量：",
                                prop: "denominatorConstituent",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入内容" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    _vm.ppSpecBeanList.denominatorConstituent =
                                      _vm.oninput(
                                        _vm.ppSpecBeanList
                                          .denominatorConstituent
                                      )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.ppSpecBeanList.denominatorConstituent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ppSpecBeanList,
                                      "denominatorConstituent",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ppSpecBeanList.denominatorConstituent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode === "2"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分母数量单位：",
                                prop: "denominatorUnit",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入分母数量单位",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择分母数量",
                                    filterable: "",
                                    "filter-method": _vm.unitFilter_fm_ordinary,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.preparationUnitList_fm_ordinary =
                                        _vm.preparationUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.denominatorUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "denominatorUnit",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ppSpecBeanList.denominatorUnit",
                                  },
                                },
                                _vm._l(
                                  _vm.preparationUnitList_fm_ordinary,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "规格单位：",
                                prop: "specUnit",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入规格单位",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择规格单位",
                                    filterable: "",
                                    "filter-method": _vm.specUnitFilter,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.specUnitList = _vm.specUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.specUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "specUnit",
                                        $$v
                                      )
                                    },
                                    expression: "ppSpecBeanList.specUnit",
                                  },
                                },
                                _vm._l(_vm.specUnitList, function (item) {
                                  return _c("el-option", {
                                    key: item.conceptId,
                                    attrs: {
                                      label: item.conceptVal,
                                      value: item.conceptId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ppSpecBeanList.specMode === "2"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "浓度：", prop: "mage" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: _vm.isConcentrationShow,
                                    placeholder: "请输入内容",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      _vm.ppSpecBeanList.maxConcentration =
                                        _vm.oninput(
                                          _vm.ppSpecBeanList.maxConcentration
                                        )
                                    },
                                  },
                                  model: {
                                    value: _vm.ppSpecBeanList.maxConcentration,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ppSpecBeanList,
                                        "maxConcentration",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ppSpecBeanList.maxConcentration",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticStyle: {
                                        "font-style": "normal",
                                        "margin-right": "10px",
                                        color: "#333333",
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    },
                                    [_vm._v("%")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.ppSpecBeanList.descList, function (item, index) {
            return _c(
              "el-form-item",
              {
                key: "spec-name-" + index,
                staticStyle: { "margin-bottom": "18px" },
                attrs: {
                  label: index == 0 ? "规格描述：" : "",
                  rules: [
                    {
                      required: true,
                      message: "请输入规格描述",
                      trigger: "blur",
                    },
                  ],
                  prop: "descList." + index + ".specDesc",
                },
              },
              [
                _c("el-input", {
                  staticStyle: { width: "600px", float: "left" },
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入规格描述",
                    maxlength: "500",
                    clearable: "",
                    "show-word-limit": "",
                  },
                  model: {
                    value: item.specDesc,
                    callback: function ($$v) {
                      _vm.$set(item, "specDesc", $$v)
                    },
                    expression: "item.specDesc",
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-remove",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.delSubstanceName(item)
                    },
                  },
                }),
                _c(
                  "el-radio",
                  {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { label: index, border: "" },
                    on: {
                      change: function (val) {
                        return _vm.primaryChange(val, "name", index)
                      },
                    },
                    model: {
                      value: _vm.ppSpecBeanList.namePrimary,
                      callback: function ($$v) {
                        _vm.$set(_vm.ppSpecBeanList, "namePrimary", $$v)
                      },
                      expression: "ppSpecBeanList.namePrimary",
                    },
                  },
                  [_vm._v(" 设置为首选项 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.splitRuleDescription(item.specDesc)
                      },
                    },
                  },
                  [_vm._v("拆分")]
                ),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "addData",
                  staticStyle: { width: "600px" },
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.addSubstanceName },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-circle-plus el-icon--left",
                    staticStyle: {
                      color: "#7C9DF7",
                      width: "16px",
                      height: "16px",
                      display: "inline-block",
                    },
                  }),
                  _vm._v(" 添加 "),
                ]
              ),
            ],
            1
          ),
          _vm.ppSpecBeanList.specMode !== "9"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-form-item", { attrs: { label: "显示规格：" } }, [
                    _vm.ppSpecBeanList.specMode === "1"
                      ? _c("span", { staticClass: "spanFontSize" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ppSpecBeanList.maxNumeratorConstituent
                              ) +
                              _vm._s(
                                _vm._f("unitChoice")(
                                  _vm.ppSpecBeanList.numeratorUnit
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.ppSpecBeanList.specMode === "2"
                      ? _c("span", { staticClass: "spanFontSize" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ppSpecBeanList.maxNumeratorConstituent
                              ) +
                              _vm._s(
                                _vm._f("unitChoice")(
                                  _vm.ppSpecBeanList.numeratorUnit
                                )
                              ) +
                              ":" +
                              _vm._s(
                                _vm.ppSpecBeanList.denominatorConstituent
                              ) +
                              _vm._s(
                                _vm._f("unitChoice")(
                                  _vm.ppSpecBeanList.denominatorUnit
                                )
                              )
                          ),
                          _vm.ppSpecBeanList.maxConcentration !== null
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.ppSpecBeanList.maxConcentration
                                    ) +
                                    "%)"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.ppSpecBeanList.specMode === "3"
                      ? _c("span", { staticClass: "spanFontSize" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.ppSpecBeanList.specLength) +
                              _vm._s(
                                _vm._f("lengthUnitChoice")(
                                  _vm.ppSpecBeanList.specLengthUnit
                                )
                              ) +
                              "×" +
                              _vm._s(_vm.ppSpecBeanList.specWidth) +
                              _vm._s(
                                _vm._f("lengthUnitChoice")(
                                  _vm.ppSpecBeanList.specWidthUnit
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.ppSpecBeanList.specMode === "9"
                      ? _c("span", { staticClass: "spanFontSize" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ppSpecBeanList.minNumeratorConstituent
                              ) +
                              _vm._s(
                                _vm._f("unitChoice")(
                                  _vm.ppSpecBeanList.numeratorUnit
                                )
                              ) +
                              ":" +
                              _vm._s(
                                _vm.ppSpecBeanList.denominatorConstituent
                              ) +
                              _vm._s(
                                _vm._f("unitChoice")(
                                  _vm.ppSpecBeanList.denominatorUnit
                                )
                              )
                          ),
                          _vm.ppSpecBeanList.minConcentration !== null
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.ppSpecBeanList.minConcentration
                                    ) +
                                    "%)"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            "～" +
                              _vm._s(
                                _vm.ppSpecBeanList.maxNumeratorConstituent
                              ) +
                              _vm._s(
                                _vm._f("unitChoice")(
                                  _vm.ppSpecBeanList.numeratorUnit
                                )
                              ) +
                              ":" +
                              _vm._s(
                                _vm.ppSpecBeanList.denominatorConstituent
                              ) +
                              _vm._s(
                                _vm._f("unitChoice")(
                                  _vm.ppSpecBeanList.denominatorUnit
                                )
                              )
                          ),
                          _vm.ppSpecBeanList.maxConcentration !== null
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.ppSpecBeanList.maxConcentration
                                    ) +
                                    "%)"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.ppSpecBeanList.specMode === "9"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: "显示规格：", "label-width": "125px" },
                    },
                    [
                      _vm.ppSpecBeanList.numeratorType === 101801
                        ? _c("span", { staticClass: "spanFontSize" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ppSpecBeanList.maxNumeratorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.numeratorUnit
                                  )
                                ) +
                                ":" +
                                _vm._s(
                                  _vm.ppSpecBeanList.denominatorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.denominatorUnit
                                  )
                                )
                            ),
                            _vm.ppSpecBeanList.maxConcentration !== null
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.ppSpecBeanList.maxConcentration
                                      ) +
                                      "%)"
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.ppSpecBeanList.numeratorType === 101803
                        ? _c("span", { staticClass: "spanFontSize" }, [
                            _vm._v(
                              " >" +
                                _vm._s(
                                  _vm.ppSpecBeanList.maxNumeratorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.numeratorUnit
                                  )
                                ) +
                                ":" +
                                _vm._s(
                                  _vm.ppSpecBeanList.denominatorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.denominatorUnit
                                  )
                                )
                            ),
                            _vm.ppSpecBeanList.maxConcentration !== null
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.ppSpecBeanList.maxConcentration
                                      ) +
                                      "%)"
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.ppSpecBeanList.numeratorType === 101804
                        ? _c("span", { staticClass: "spanFontSize" }, [
                            _vm._v(
                              " >=" +
                                _vm._s(
                                  _vm.ppSpecBeanList.maxNumeratorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.numeratorUnit
                                  )
                                ) +
                                ":" +
                                _vm._s(
                                  _vm.ppSpecBeanList.denominatorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.denominatorUnit
                                  )
                                )
                            ),
                            _vm.ppSpecBeanList.maxConcentration !== null
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.ppSpecBeanList.maxConcentration
                                      ) +
                                      "%)"
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.ppSpecBeanList.numeratorType === 101805
                        ? _c("span", { staticClass: "spanFontSize" }, [
                            _vm._v(
                              " <" +
                                _vm._s(
                                  _vm.ppSpecBeanList.maxNumeratorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.numeratorUnit
                                  )
                                ) +
                                ":" +
                                _vm._s(
                                  _vm.ppSpecBeanList.denominatorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.denominatorUnit
                                  )
                                )
                            ),
                            _vm.ppSpecBeanList.maxConcentration !== null
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.ppSpecBeanList.maxConcentration
                                      ) +
                                      "%)"
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.ppSpecBeanList.numeratorType === 101806
                        ? _c("span", { staticClass: "spanFontSize" }, [
                            _vm._v(
                              " <=" +
                                _vm._s(
                                  _vm.ppSpecBeanList.maxNumeratorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.numeratorUnit
                                  )
                                ) +
                                ":" +
                                _vm._s(
                                  _vm.ppSpecBeanList.denominatorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.denominatorUnit
                                  )
                                )
                            ),
                            _vm.ppSpecBeanList.maxConcentration !== null
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.ppSpecBeanList.maxConcentration
                                      ) +
                                      "%)"
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.ppSpecBeanList.numeratorType === 101807
                        ? _c("span", { staticClass: "spanFontSize" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ppSpecBeanList.minNumeratorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.numeratorUnit
                                  )
                                ) +
                                ":" +
                                _vm._s(
                                  _vm.ppSpecBeanList.denominatorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.denominatorUnit
                                  )
                                )
                            ),
                            _vm.ppSpecBeanList.minConcentration !== null
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.ppSpecBeanList.minConcentration
                                      ) +
                                      "%)"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(
                              "～" +
                                _vm._s(
                                  _vm.ppSpecBeanList.maxNumeratorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.numeratorUnit
                                  )
                                ) +
                                ":" +
                                _vm._s(
                                  _vm.ppSpecBeanList.denominatorConstituent
                                ) +
                                _vm._s(
                                  _vm._f("unitChoice")(
                                    _vm.ppSpecBeanList.denominatorUnit
                                  )
                                )
                            ),
                            _vm.ppSpecBeanList.maxConcentration !== null
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.ppSpecBeanList.maxConcentration
                                      ) +
                                      "%)"
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-footer",
        { staticStyle: { "text-align": "right" } },
        [
          _c("el-button", { on: { click: _vm.closeAddSF } }, [_vm._v("取消")]),
          _c("el-button", { on: { click: _vm.resetForm } }, [_vm._v("重置")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveSpec("ppSpecBeanListref")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择制剂规格",
            width: "60%",
            "append-to-body": "",
            visible: _vm.specSelectDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.specSelectDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { height: "400px", overflow: "auto" } },
            [
              _c(
                "p",
                { staticStyle: { "font-size": "15px", "font-weight": "bold" } },
                [
                  _vm._v(
                    "根据当前已输入的规格描述，匹配到多个制剂规格，请选择一个制剂规格"
                  ),
                ]
              ),
              _c("el-divider"),
              _c(
                "ul",
                { staticStyle: { "font-size": "15px" } },
                _vm._l(_vm.specList, function (item, index) {
                  return _c(
                    "li",
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.numeratorVal) +
                            _vm._s(item.numeratorUnitDesc)
                        ),
                      ]),
                      item.denominatorVal
                        ? _c("span", [
                            _vm._v(
                              ":" +
                                _vm._s(item.denominatorVal) +
                                _vm._s(item.denominatorUnitDesc)
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.selectSpec(item)
                            },
                          },
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }