// 新增规格数据实体
export const PPSPECBEANLIST_DATA = {
  specMode: null, // 规格模式
  // concentrationType: null,
  specGroupKey: Math.random().toString(),
  convertFactor: null, // 换算系数
  denominatorConstituent: null,
  denominatorUnit: null,
  denominatorUnitStr: null,
  descList: [], // 规格描述的集合
  id: null,
  isDefault: true, //默认为首选规格
  maxConcentration: null,
  maxNumeratorConstituent: null,
  minConcentration: null,
  minNumeratorConstituent: null,
  numeratorType: 101801,
  numeratorTypeStr: null,
  numeratorUnit: null,
  preparationId: null,
  specId: null,
  specUnit: null,
  specUnitStr: null,
  status: '0',
  showSpec: null,
  specLength: null,
  specLengthUnit: null,
  specWidth: null,
  specWidthUnit: null,
  // type: [],
  namePrimary: 0
}

